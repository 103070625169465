/* eslint-disable-next-line import/no-unresolved */
import { useMemo, useCallback, useEffect, useRef, useState } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector, useDispatch } from 'react-redux';
import Decimal from 'decimal.js';
import {
  changeParentTagId,
  getSelectionsByParamsRequest,
  setFilterInitKeyword,
  removeInitialChildTag,
  saveAutoSelectSortOrder,
} from '../../redux/actions/autoSelectActions';
import { getPublishedLabsRequest } from '../../redux/labs';
import { changeServiceIdRequest, openLoginAlert } from '../../redux/actions/authActions';
import { useAccountInfo, useSelectedStrategyGroup } from '../../hooks';
import { checkIsWebApp } from '../index';
import {
  MESSAGE_NO_RESULT,
  SELECTION_OPTIONS,
  SELECTION_OPTIONS_SELECTED_LABEL_MAP,
  SERVICE_TAG_MAPPING,
  SHARE_RETURN_TAG_ID_MAPPING,
  TAG_ID_ALL,
  TAG_SERVICE_MAPPING,
} from '../../constants/select';
import { isArray, isEqual, roundUpBy1000 } from '../../utils';
import { ALL_SERVICES } from '../../constants/core';
import { getAllTag, getDefaultChild, getParentTag, getSortedData, getUnavailable } from '../../utils/select';
import { useLoadingSelections } from '../../hooks/select';
import { useCheckAndNotifyAvailableService } from '../../hooks/service';

const useFilterCondition = (selectionsData, selectedAllTab) => {
  const { filterCondition } = useSelector((state) => state.autoSelect);
  const accountInfo = useAccountInfo();
  return useMemo(() => {
    if (!selectionsData?.length) {
      return selectionsData;
    }
    let data = selectionsData.filter(({ serviceIds }) =>
      serviceIds.every((serviceId) => !accountInfo[serviceId].isNotAvailable),
    );
    let recommendations = data.filter((row) => row.recommendation);
    data = data.filter((row) => !row.recommendation);
    const { assets, margins, periods, comprehensiveEvaluations, styles, rangeOuts } = filterCondition;
    if (selectedAllTab && assets != null) {
      const conditions = Object.keys(assets).filter((key) => assets[key]);
      if (conditions.length > 0) {
        const assetFilter = ({ serviceIds }) => conditions.some((asset) => serviceIds.includes(asset));
        recommendations = recommendations.map((row) => ({ ...row, isMatchAsset: assetFilter(row) }));
        data = data.filter(assetFilter);
      }
    }
    if (margins != null) {
      const conditions = Object.keys(margins).filter((key) => margins[key]);
      if (conditions.length > 0) {
        const filter1 = margins['0'] ? (d) => d < 500000 : () => false;
        const filter2 = margins['1'] ? (d) => d >= 500000 && d < 1000000 : () => false;
        const filter3 = margins['2'] ? (d) => d >= 1000000 && d < 2000000 : () => false;
        const filter4 = margins['3'] ? (d) => d >= 2000000 : () => false;
        data = data.filter(({ defaultSets, simulationStats }) => {
          const { marginRecommended } = simulationStats || {};
          if (marginRecommended == null) {
            return false;
          }
          const d = roundUpBy1000(new Decimal(marginRecommended).mul(defaultSets ?? 0));
          return filter1(d) || filter2(d) || filter3(d) || filter4(d);
        });
      }
    }
    if (styles != null) {
      const conditions = Object.keys(styles).filter((key) => styles[key]);
      if (conditions.length > 0) {
        data = data.filter((row) => conditions.includes(row.style));
      }
    }
    if (periods != null) {
      const conditions = Object.keys(periods).filter((key) => periods[key]);
      if (conditions.length > 0) {
        data = data.filter((row) => {
          let targetPeriod;
          if (row.labId == null) {
            targetPeriod = row.period;
          } else if (row.technicalTerm == null) {
            targetPeriod = null;
          } else {
            targetPeriod = String(row.technicalTerm);
          }
          return conditions.includes(targetPeriod);
        });
      }
    }
    if (comprehensiveEvaluations != null) {
      const conditions = Object.keys(comprehensiveEvaluations)
        .filter((key) => comprehensiveEvaluations[key])
        .map((key) => Number(key));
      if (conditions.length > 0) {
        data = data.filter(({ attribute }) => {
          return attribute && conditions.includes(Number(attribute?.comprehensiveEvaluation ?? 0));
        });
      }
    }
    if (rangeOuts?.excludeRangeOut === true) {
      data = data.filter(({ attribute }) => {
        const { isRangeOut } = attribute ?? {};
        return isRangeOut !== true;
      });
    }
    return recommendations.concat(data);
  }, [filterCondition, selectionsData, selectedAllTab, accountInfo]);
};

const useSelectionData = ({ filterOption, isAll, selectedChildTag }) => {
  const [selectionData, setSelectionData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { selectionTags: tags, activeSelections, initialSearchText } = useSelector((state) => state.autoSelect);
  const activeSelectionData = useMemo(() => {
    if (activeSelections == null || selectedChildTag == null) {
      return activeSelections;
    }
    return activeSelections.map((row) => ({
      ...row,
      // 「おすすめ」フラグ
      recommendation: selectedChildTag.recommendationId.includes(row.selectionId),
      // 管理画面で指定した「おすすめ」のソート順
      recommendationSort: selectedChildTag.recommendationId.includes(row.selectionId)
        ? selectedChildTag.recommendationId.indexOf(row.selectionId) + 1
        : null,
    }));
  }, [activeSelections, selectedChildTag]);
  const labs = useSelector((state) => state.labs.publishedLabs.list);
  const loading = useLoadingSelections();
  const [innerLoading, setInnerLoading] = useState(loading);

  const clear = useCallback(() => {
    setSelectionData([]);
  }, []);

  useEffect(() => {
    if (loading) {
      setInnerLoading(loading);
    }
  }, [loading]);

  // 一覧データ準備
  useEffect(() => {
    // redux より react state の更新の方が先行するのでここで待ち合わせる
    if (loading) {
      return;
    }
    try {
      let data = [].concat(activeSelectionData || []).concat(labs || []);
      if (searchText) {
        data = data
          .map((row) => ({ ...row, isMatchSearchText: row?.name?.includes?.(searchText) }))
          .filter((row) => row?.recommendation || row?.isMatchSearchText);
      }
      setSelectionData(getSortedData(filterOption, data));
    } finally {
      setInnerLoading(false);
    }
  }, [searchText, loading, filterOption, activeSelectionData, labs]);

  // 一覧データに絞り込み条件を適用
  const filteredData = useFilterCondition(selectionData, isAll);

  // 検索キーワードの初期値を設定
  useEffect(() => {
    setSearchText(initialSearchText);
  }, [initialSearchText]);

  return useMemo(() => {
    return {
      loading: innerLoading,
      clear,
      tags,
      selectionData: filteredData,
      searchText,
      setSearchText,
    };
  }, [innerLoading, clear, tags, filteredData, searchText, setSearchText]);
};

const needDisplayMessage = (isMatchRecommendation, isEmptyNotRecommendation) =>
  isMatchRecommendation ? false : isEmptyNotRecommendation;

const useNotMatchCondition = ({ data, isSearched }) => {
  const filterCondition = useSelector((state) => state.autoSelect.filterCondition);
  const isFiltered = Object.values(filterCondition).some((condition) => Object.values(condition).some((val) => val));
  const recommendations = data.filter((row) => row.recommendation);
  const isEmptyNotRecommendation = data.length - recommendations.length === 0;
  if (recommendations.length === 0) {
    return isEmptyNotRecommendation;
  }
  // おすすめでisMatchAssetがtrueになるのはすべてタブを選択しているときであるため、明示的にすべてタブを選択しているかどうかのチェックは行わない
  if (isFiltered && isSearched) {
    const isMatchRecommendation = recommendations.filter((row) => row.isMatchAsset && row.isMatchSearchText).length > 0;
    return needDisplayMessage(isMatchRecommendation, isEmptyNotRecommendation);
  }
  if (isFiltered && !isSearched) {
    const isMatchRecommendation = recommendations.filter((row) => row.isMatchAsset).length > 0;
    return needDisplayMessage(isMatchRecommendation, isEmptyNotRecommendation);
  }
  if (!isFiltered && isSearched) {
    const isMatchRecommendation = recommendations.filter((row) => row.isMatchSearchText).length > 0;
    return needDisplayMessage(isMatchRecommendation, isEmptyNotRecommendation);
  }
  return false;
};

const noMatchAssetFilter = (row) => {
  if (!row.recommendation) {
    return true;
  }
  // アセットの絞り込みがされていない場合はisMatchAssetはundefined
  return row.isMatchAsset == null || row.isMatchAsset === true;
};

export const useAutoSelectInfo = () => {
  const dispatch = useDispatch();
  const [, setStrategyGroup] = useSelectedStrategyGroup();
  const [selectedParentTagId, setSelectedParentTagId] = useState(null);
  const [selectedChildTagId, setSelectedChildTagId] = useState(null);
  const [selectedChildTag, setSelectedChildTag] = useState(null);
  const [selectedShareTagId, setSelectedShareTagId] = useState(null);

  const selectedFilterOption = useSelector((state) => state.autoSelect.sortOrder);
  const setSelectedFilterOption = useCallback(
    (sortOrder) => {
      dispatch(saveAutoSelectSortOrder({ sortOrder }));
    },
    [dispatch],
  );

  const setFilterKeyword = useCallback(
    (keyword) => {
      dispatch(setFilterInitKeyword({ keyword }));
    },
    [dispatch],
  );

  const accountInfo = useAccountInfo();
  const checkAndNotifyAvailableService = useCheckAndNotifyAvailableService();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const { loading, clear, tags, selectionData, searchText } = useSelectionData({
    filterOption: selectedFilterOption,
    isAll: selectedParentTagId === TAG_ID_ALL,
    selectedChildTag,
  });
  const allTagRef = useRef(getAllTag(tags));
  useEffect(() => {
    allTagRef.current = getAllTag(tags);
  }, [tags]);

  const accountInfoRef = useRef(accountInfo);
  useEffect(() => {
    accountInfoRef.current = accountInfo;
  }, [accountInfo]);

  // isAuth の切り替わり時、すでにデータ取得済みだった場合は最新化する
  const requestedSelectionTagIdRef = useRef(null);
  const requestedShareTagIdRef = useRef(null);
  useEffect(() => {
    if (requestedSelectionTagIdRef.current != null) {
      dispatch(getSelectionsByParamsRequest({ tagId: requestedSelectionTagIdRef.current }));
    }
    if (requestedShareTagIdRef.current != null) {
      dispatch(getPublishedLabsRequest({ tagId: requestedShareTagIdRef.current }));
    }
  }, [dispatch, isAuth]); // isAuth は変更検知目的

  const storedParentTagId = useSelector((state) => state.autoSelect.parentTagId);
  const childTagLabel = useSelector((state) => state.autoSelect.initialChildTag?.fullName);
  // 破棄時に store を null クリア
  useEffect(
    () => () => {
      dispatch(changeParentTagId({ parentTagId: null }));
    },
    [dispatch],
  );

  // タブ
  const parentOptions = useMemo(() => {
    return tags.map(({ tagId, name, badge }) => {
      const service = TAG_SERVICE_MAPPING[tagId];
      return {
        id: tagId,
        value: name,
        badge,
        disabled: false,
        // すべての場合は null になり、常に有効
        isVisuallyDisabled: service ? accountInfo[service].isNotAvailable : false,
      };
    });
  }, [tags, accountInfo]);

  const validTags = useMemo(() => {
    const idSet = new Set(
      parentOptions.filter(({ disabled, isVisuallyDisabled }) => !(disabled || isVisuallyDisabled)).map(({ id }) => id),
    );
    return tags.filter(({ tagId }) => idSet.has(tagId));
  }, [tags, parentOptions]);

  const changeChildState = useCallback(
    (childTagId, skipNotification) => {
      const childTags = getParentTag(tags, selectedParentTagId)?.children ?? [];
      const targetChildTag = childTags.find(({ tagId }) => tagId === childTagId);
      const unavailableService = getUnavailable({
        child: targetChildTag,
        allTag: allTagRef.current,
        accountInfo: accountInfoRef.current,
      });
      if (!skipNotification) {
        if (unavailableService) {
          if (isAuth) {
            checkAndNotifyAvailableService(accountInfoRef.current, unavailableService);
          } else {
            dispatch(openLoginAlert());
          }
          return;
        }
      }
      clear();
      setSelectedChildTag(targetChildTag);
      setSelectedChildTagId(childTagId);
      setSelectedShareTagId(
        targetChildTag?.shareFlg === true ? SHARE_RETURN_TAG_ID_MAPPING[selectedParentTagId] : null,
      );
    },
    [tags, selectedParentTagId, clear, checkAndNotifyAvailableService, isAuth, dispatch],
  );

  const changeParentState = useCallback(
    (newParentTagId) => {
      if (selectedParentTagId === newParentTagId) {
        return;
      }
      const selectedServiceId = TAG_SERVICE_MAPPING[newParentTagId];
      const action = changeParentTagId({ parentTagId: newParentTagId });
      if (selectedServiceId) {
        dispatch(changeServiceIdRequest({ serviceId: selectedServiceId, successActionCreator: () => action }));
      } else {
        // 全ての場合は無条件で変更
        dispatch(action);
      }
      // 選択が確定するのは autoSelect.parentTagId の変更検知後となる
    },
    [dispatch, selectedParentTagId],
  );

  const disabledParentTagIdSet = useMemo(() => {
    return ALL_SERVICES.reduce((acc, curr) => {
      if (accountInfo[curr].isNotAvailable) {
        acc.add(String(SERVICE_TAG_MAPPING[curr]));
      }
      return acc;
    }, new Set());
  }, [accountInfo]);

  const getEnableChildTagIds = useCallback(
    (childTagId) => {
      const childTagIds = isArray(childTagId) ? childTagId : [childTagId];
      return childTagIds.filter((cid) => {
        const pid = String(cid).substring(0, 1);
        return !disabledParentTagIdSet.has(pid);
      });
    },
    [disabledParentTagIdSet],
  );

  // セレクトデータ取得
  useEffect(() => {
    const enableChildTagIds = getEnableChildTagIds(selectedChildTagId);
    if (enableChildTagIds.length === 0) {
      requestedSelectionTagIdRef.current = null;
      dispatch(getSelectionsByParamsRequest({ tagId: null }));
    } else {
      requestedSelectionTagIdRef.current = enableChildTagIds;
      dispatch(getSelectionsByParamsRequest({ tagId: enableChildTagIds }));
    }
  }, [dispatch, selectedChildTagId, getEnableChildTagIds]);

  // シェアデータ取得
  useEffect(() => {
    if (selectedShareTagId == null) {
      requestedShareTagIdRef.current = null;
      dispatch(getPublishedLabsRequest({ tagId: null }));
    } else {
      const enableShareTagIds = getEnableChildTagIds(selectedShareTagId);
      if (enableShareTagIds.length === 0) {
        requestedShareTagIdRef.current = null;
        dispatch(getPublishedLabsRequest({ tagId: null }));
      } else if (!isEqual(enableShareTagIds, requestedShareTagIdRef.current)) {
        requestedShareTagIdRef.current = enableShareTagIds;
        const param = { tagId: enableShareTagIds };
        dispatch(getPublishedLabsRequest(param));
      }
    }
  }, [dispatch, selectedShareTagId, getEnableChildTagIds]);

  // 項目ボタン
  const childrenOptions = useMemo(() => {
    return (
      getParentTag(tags, selectedParentTagId)?.children?.map?.((childTag) => ({
        id: childTag.tagId,
        value: childTag.name,
        badge: childTag.badge,
        visuallyDisabled: getUnavailable({ child: childTag, allTag: allTagRef.current, accountInfo }) != null,
      })) ?? []
    );
  }, [tags, selectedParentTagId, accountInfo]);

  // タブの初期選択
  useEffect(() => {
    if (selectedParentTagId == null && validTags?.length) {
      let found = validTags.find(({ defaultFlg }) => defaultFlg);
      if (found == null && validTags.length > 0) {
        [found] = validTags;
      }
      if (found) {
        changeParentState(found.tagId);
      }
    }
  }, [selectedParentTagId, validTags, changeParentState]);

  // auth.serviceId の変更に成功した場合は選択済みとする
  useEffect(() => {
    if (storedParentTagId != null) {
      setSelectedParentTagId(storedParentTagId);
    }
  }, [storedParentTagId]);

  // タブ選択が確定したらデフォルト項目を設定
  useEffect(() => {
    const foundChild = getParentTag(tags, selectedParentTagId)?.children?.find(
      (child) => child.tagId === selectedChildTagId, // strict type
    );
    if (foundChild != null && selectedChildTagId != null && getEnableChildTagIds(selectedChildTagId).length > 0) {
      // 選択している項目が有効であればそのまま維持
      return undefined; // without cleanup
    }
    let defaultChildTagId = getDefaultChild(tags, selectedParentTagId)?.tagId;
    if (!defaultChildTagId || getEnableChildTagIds(defaultChildTagId).length === 0) {
      // デフォルト項目が見つからないか見つかっても有効でなかった場合は有効な項目を検索する
      defaultChildTagId = getParentTag(tags, selectedParentTagId)
        ?.children?.map((child) => child.tagId)
        .find((childTagId) => getEnableChildTagIds(childTagId)?.length > 0);
    }
    // stateで指定されていたら、それを優先
    let doRemoveInitTag = false;
    if (childTagLabel) {
      const found = getParentTag(tags, selectedParentTagId)
        ?.children?.filter(({ tagId: childTagId }) => getEnableChildTagIds(childTagId)?.length > 0)
        ?.find((tag) => tag.fullName.toString() === childTagLabel.toString()); // strict type
      if (found) {
        defaultChildTagId = found.tagId;
        doRemoveInitTag = true; // 使ったら削除
      }
    }
    if (defaultChildTagId != null) {
      changeChildState(defaultChildTagId, true);
    }
    return () => {
      if (doRemoveInitTag) {
        dispatch(removeInitialChildTag()); // 使ったら削除
      }
    };
  }, [tags, selectedParentTagId, selectedChildTagId, getEnableChildTagIds, changeChildState, childTagLabel, dispatch]);

  // カードの初期選択
  useEffect(() => {
    // mobile は初期選択不要
    if (!checkIsWebApp() || loading) {
      return;
    }
    setStrategyGroup(selectionData?.[0]);
  }, [loading, setStrategyGroup, selectionData]);

  const isNotMatchCondition = useNotMatchCondition({
    data: selectionData,
    isSearched: !!searchText,
  });

  return useMemo(
    () => ({
      tabs: {
        get: selectedParentTagId,
        set: changeParentState,
        options: parentOptions,
      },
      search: {
        get: searchText,
        set: setFilterKeyword,
        placeholder: '検索',
        modal: { title: 'ルール名で検索', submit: '検索' },
      },
      filter: {
        get: selectedFilterOption,
        set: setSelectedFilterOption,
        options: SELECTION_OPTIONS,
        getLabel: SELECTION_OPTIONS_SELECTED_LABEL_MAP[selectedFilterOption],
      },
      sort: {
        get: selectedChildTagId,
        set: changeChildState,
        options: childrenOptions,
      },
      selections: {
        data: selectionData.filter(noMatchAssetFilter),
        isEmpty: isNotMatchCondition,
        emptyMessage: MESSAGE_NO_RESULT,
      },
      isLoading: loading,
    }),
    [
      selectedParentTagId,
      changeParentState,
      parentOptions,
      selectedChildTagId,
      changeChildState,
      childrenOptions,
      selectedFilterOption,
      loading,
      selectionData,
      searchText,
      setSelectedFilterOption,
      setFilterKeyword,
      isNotMatchCondition,
    ],
  );
};
