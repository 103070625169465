import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COUNTRY_TYPE, COUNTRIES_AVAILABLE_VALUES } from 'shared-modules/constants';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import { ReactComponent as AUDFlag } from '../../assets/flags/AUD.svg';
import { ReactComponent as CADFlag } from '../../assets/flags/CAD.svg';
import { ReactComponent as CHFFlag } from '../../assets/flags/CHF.svg';
import { ReactComponent as EURFlag } from '../../assets/flags/EUR.svg';
import { ReactComponent as GBPFlag } from '../../assets/flags/GBP.svg';
import { ReactComponent as JPYFlag } from '../../assets/flags/JPY.svg';
import { ReactComponent as NZDFlag } from '../../assets/flags/NZD.svg';
import { ReactComponent as USDFlag } from '../../assets/flags/USD.svg';
import { ReactComponent as ZARFlag } from '../../assets/flags/ZAR.svg';
import { ReactComponent as TRYFlag } from '../../assets/flags/TRY.svg';
import { ReactComponent as PLNFlag } from '../../assets/flags/PLN.svg';
import { ReactComponent as MXNFlag } from '../../assets/flags/MXN.svg';
import { ReactComponent as NOKFlag } from '../../assets/flags/NOK.svg';
import { ReactComponent as SEKFlag } from '../../assets/flags/SEK.svg';
import styles from './customFlag.module.scss';

const CustomFlag = ({ country, className }) => {
  const countrySymbol = removeSuffix(country);
  const Tag = useMemo(() => {
    switch (countrySymbol) {
      case COUNTRY_TYPE.AUD: {
        return AUDFlag;
      }
      case COUNTRY_TYPE.CAD: {
        return CADFlag;
      }
      case COUNTRY_TYPE.CHF: {
        return CHFFlag;
      }
      case COUNTRY_TYPE.EUR: {
        return EURFlag;
      }
      case COUNTRY_TYPE.GBP: {
        return GBPFlag;
      }
      case COUNTRY_TYPE.JPY: {
        return JPYFlag;
      }
      case COUNTRY_TYPE.NZD: {
        return NZDFlag;
      }
      case COUNTRY_TYPE.USD: {
        return USDFlag;
      }
      case COUNTRY_TYPE.ZAR: {
        return ZARFlag;
      }
      case COUNTRY_TYPE.TRY: {
        return TRYFlag;
      }
      case COUNTRY_TYPE.PLN: {
        return PLNFlag;
      }
      case COUNTRY_TYPE.MXN: {
        return MXNFlag;
      }
      case COUNTRY_TYPE.NOK: {
        return NOKFlag;
      }
      case COUNTRY_TYPE.SEK: {
        return SEKFlag;
      }
      default: {
        return null;
      }
    }
  }, [countrySymbol]);

  if (!Tag) return <div className={classNames(className, styles.emptyFlag)} />;
  return <Tag className={className} />;
};
CustomFlag.propTypes = {
  country: PropTypes.oneOf(COUNTRIES_AVAILABLE_VALUES),
  className: PropTypes.string,
};
CustomFlag.defaultProps = {
  country: '',
  className: '',
};

export default memo(CustomFlag);
