import { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { TUTORIAL_MODE_SELECT_ITEM_NAME } from 'shared-modules/constants/tutorial';
import { useAutoSelectInfo } from 'shared-modules/services/hooks/autoSelectMainViewLogic';
import { useSelectedStrategyGroup } from 'shared-modules/hooks';
import {
  getSelectionsByParamsSuccess,
  navigateToRecommendSelectionEnd,
} from 'shared-modules/redux/actions/autoSelectActions';
import { tutorialSideMenu } from '../../../../constants/tutorial/classNames';
import { useAppHeaderHeight } from '../../../../hooks';
import { Dropdown, ItemButtons, Spin, Tabs } from '../../../../components';
import { CardItem } from '../CardItem';
import styles from './sideMenu.module.scss';
import { CardDescription } from '../CardDescription';
import { FilterCondition } from '../FilterCondition';
import { SearchButton } from '../SearchButton';

export const SideMenu = memo(() => {
  const headerHeight = useAppHeaderHeight();
  const autoSelectData = useAutoSelectInfo();
  const [tabWidth, setTabWidth] = useState(115);

  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);

  const dispatch = useDispatch();

  const activeSelections = useSelector((state) => state.autoSelect.activeSelections);
  useEffect(() => {
    if (tutorialMode && activeSelections?.length > 1) {
      dispatch(
        getSelectionsByParamsSuccess({
          selectionsData: activeSelections?.filter(({ name }) => name === TUTORIAL_MODE_SELECT_ITEM_NAME),
        }),
      );
    }
  }, [activeSelections, dispatch, tutorialMode]);

  const { isNavigatingToRecommendSelection, parentTagId, childrenTagId, selectionInfo } = useSelector(
    (state) => state.autoSelect.recommendSelection,
  );

  const [strategyGroup, setStrategyGroup] = useSelectedStrategyGroup();

  // レコメンドモーダルのセレクトを謳歌して移動してきた場合、選択されたセレクトのある親タグ、子タグを選択し、
  // 対象のセレクトが選択状態で表示する
  useEffect(() => {
    if (!isNavigatingToRecommendSelection) return;
    if (autoSelectData.tabs.get !== parentTagId) {
      autoSelectData.tabs.set(parentTagId);
      return;
    }
    if (autoSelectData.sort.get !== childrenTagId) {
      autoSelectData.sort.set(childrenTagId);
      return;
    }
    if (!autoSelectData.isLoading && selectionInfo.selectionId !== strategyGroup?.selectionId) {
      setStrategyGroup(selectionInfo);
      return;
    }
    if (
      autoSelectData.tabs.get === parentTagId &&
      autoSelectData.sort.get === childrenTagId &&
      selectionInfo.selectionId === strategyGroup?.selectionId &&
      !autoSelectData.isLoading
    )
      dispatch(navigateToRecommendSelectionEnd());
  }, [
    isNavigatingToRecommendSelection,
    parentTagId,
    childrenTagId,
    autoSelectData.isLoading,
    strategyGroup?.selectionId,
    dispatch,
    autoSelectData.sort,
    autoSelectData.tabs,
    selectionInfo,
    setStrategyGroup,
  ]);

  const selectionItems = useMemo(() => {
    if (autoSelectData.isLoading) {
      return <Spin className={styles.loader} />;
    }
    if (strategyGroup == null && !autoSelectData.selections.data.length) {
      return <div className={styles.noResultsText}>{autoSelectData.selections.emptyMessage}</div>;
    }
    if (tutorialMode) {
      // コアレンジャー_豪ドル / NZドルの情報のみを表示する
      return autoSelectData.selections.data
        .filter(({ name }) => name === TUTORIAL_MODE_SELECT_ITEM_NAME)
        .map((item) => <CardItem key={item.labId ?? `${item.selectionId}_${item.selectVersionId}`} data={item} />);
    }
    return (
      <>
        {autoSelectData.selections.data.map((item) => (
          <CardItem key={item.labId ?? `${item.selectionId}_${item.selectVersionId}`} data={item} />
        ))}
      </>
    );
  }, [
    autoSelectData.isLoading,
    autoSelectData.selections.data,
    autoSelectData.selections.emptyMessage,
    tutorialMode,
    strategyGroup,
  ]);

  const tabItem = useMemo(() => {
    return (
      <div className={styles.toolArea}>
        <div className={styles.itemButtonsArea}>
          <ItemButtons
            className={styles.itemButtons}
            noScrollClassName={styles.noScrollItemButtons}
            options={autoSelectData.sort.options}
            activeId={autoSelectData.sort.get}
            onChange={autoSelectData.sort.set}
          />
        </div>
        <div className={styles.searchCondition}>
          <CardDescription />
          <div className={styles.tools}>
            <FilterCondition tabs={autoSelectData.tabs} />
            <div className={styles.sort}>
              <Dropdown
                iconName="sort"
                minWidth={55}
                offsetTop={headerHeight}
                items={autoSelectData.filter.options}
                selected={autoSelectData.filter.get}
                onChange={autoSelectData.filter.set}
                labelClassName={styles.innerLabelWrapper}
                selectedLabel={autoSelectData.filter.getLabel}
              />
            </div>
            <SearchButton autoSelectData={autoSelectData} />
          </div>
        </div>
        <div className={styles.selectionArea}>{selectionItems}</div>
      </div>
    );
  }, [headerHeight, selectionItems, autoSelectData]);

  const tabItems = useMemo(() => {
    return autoSelectData.tabs.options.map((options) => ({ ...options, children: tabItem }));
  }, [autoSelectData.tabs.options, tabItem]);

  const handleResize = useCallback(
    ({ width }) => {
      const length = tabItems?.length ?? 0;
      if (length > 0) {
        setTabWidth((width ?? 0) / length);
      }
    },
    [tabItems],
  );

  return (
    <div className={classNames(styles.container, tutorialSideMenu)}>
      <div className={styles.tabArea}>
        {autoSelectData.tabs.get != null && (
          <Tabs
            type="line"
            tabMinWidth={tabWidth}
            scrollWidth={tabWidth}
            items={tabItems}
            activeKey={autoSelectData.tabs.get}
            onChange={autoSelectData.tabs.set}
            onReady={handleResize}
            onResize={handleResize}
          />
        )}
      </div>
    </div>
  );
});
