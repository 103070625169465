import { compareServiceId, isEqual } from '.';
import { ALL_SERVICES } from '../constants/core';
import { ORDER_EVALUATION, ORDER_POPULAR, ORDER_RETURN, SERVICE_TAG_MAPPING, TAG_ID_ALL } from '../constants/select';

const concatHighPrioritySortFunc = (sortFunc) => {
  return (a, b) => {
    if (a.recommendation && b.recommendation) {
      // serviceId に含まれるのがサービスIDと MIXED(all) のみなので compareServiceId が利用可能
      // serviceIdが違う場合serviceIdでソートする。同じ場合はrecommendationSortでソートする
      if (a.serviceId === b.serviceId) {
        return a.recommendationSort - b.recommendationSort;
      }
      return compareServiceId(a.serviceId, b.serviceId);
    }
    if (a.recommendation) {
      return -1;
    }
    if (b.recommendation) {
      return 1;
    }
    // 以下はおすすめを含まない要素の比較
    return sortFunc(a, b);
  };
};

const compareReturn = (a, b) => {
  const result = b.simulationStats.roi - a.simulationStats.roi;
  if (result === 0) {
    // シェアの場合、第2ソートキーは ID
    return (b.labId ?? 0) - (a.labId ?? 0);
  }
  return result;
};

export const getSortedData = (option, data) => {
  let sortingFunc;
  const sortingData = [...data];
  switch (option) {
    case ORDER_RETURN: {
      sortingFunc = compareReturn;
      break;
    }
    case ORDER_POPULAR: {
      sortingFunc = (a, b) => {
        const result =
          (b.cartAddedCount ?? b.simulationStats.instanceCount) - (a.cartAddedCount ?? a.simulationStats.instanceCount);
        if (result === 0) {
          // シェアの場合、第2ソートキーは ID
          return (b.labId ?? 0) - (a.labId ?? 0);
        }
        return result;
      };
      break;
    }
    case ORDER_EVALUATION: {
      sortingFunc = (a, b) => {
        const ca1 = a.attribute?.comprehensiveEvaluation ?? 0;
        const ca2 = b.attribute?.comprehensiveEvaluation ?? 0;
        const result = ca2 - ca1; // 大きい順
        if (result === 0) {
          // 第2ソートは Return順
          return compareReturn(a, b);
        }
        return result;
      };
      break;
    }
    default: {
      sortingFunc = () => 0;
    }
  }
  return sortingData.sort(concatHighPrioritySortFunc(sortingFunc));
};

export const getParentTag = (tags, parentTagId) => tags?.find((tag) => tag.tagId === parentTagId);

export const getAllTag = (tags) => getParentTag(tags, TAG_ID_ALL);

export const getDefaultChild = (tags, parentTagId) => {
  const children = getParentTag(tags, parentTagId)?.children ?? [];
  const found = children.find(({ defaultFlg }) => defaultFlg);
  if (found) {
    return found;
  }
  if (children.length) {
    return children[0];
  }
  return null;
};

// 項目が利用不可かどうかをチェックし、利用不可の場合その原因となるサービスIDを返す
export const getUnavailable = ({ child, allTag, accountInfo }) => {
  if (child == null) {
    return null;
  }
  if (allTag) {
    const allOnlyChildIds = allTag.children
      ?.filter(({ parentTagIds }) => parentTagIds?.length === 1 && parentTagIds[0] === TAG_ID_ALL)
      .map(({ tagId }) => tagId);
    // tagId is array or string
    if (allOnlyChildIds.some((childTagId) => isEqual(childTagId, child.tagId))) {
      const found = ALL_SERVICES.find((serviceId) => accountInfo[serviceId].isNotAvailable);
      if (found) {
        // すべてにしか紐付かない項目はいずれかのサービスが利用不可であれば利用不可
        return found;
      }
    }
  }
  if (!child.parentTagIds?.length) {
    return null;
  }
  const unavailableMap = ALL_SERVICES.filter((serviceId) => accountInfo[serviceId].isNotAvailable).reduce(
    (acc, curr) => {
      return {
        ...acc,
        [SERVICE_TAG_MAPPING[curr]]: curr,
      };
    },
    {},
  );
  const unavailableServiceSet = new Set(Object.values(unavailableMap));
  if (unavailableServiceSet.size === 0) {
    return null;
  }
  const availableParentTagIds = child.parentTagIds.filter((parentTagId) => !unavailableMap[parentTagId]);
  if (
    availableParentTagIds.length === 0 ||
    (availableParentTagIds.length === 1 && availableParentTagIds[0] === TAG_ID_ALL)
  ) {
    return unavailableMap[child.parentTagIds.find((parentTagId) => unavailableMap[parentTagId])];
  }
  return null;
};
