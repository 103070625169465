/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import Decimal from 'decimal.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuid } from 'uuid';
import {
  AP_GROUP_STATUSES,
  BUY_SELL_MAIN,
  CARD_STATUS_IDS,
  CHART_RESOLUTION_VALUES,
  COUNTRY_TYPE,
  ETF,
  FX,
  INDICATORS_TYPES,
  INDICATOR_IDS,
  MARGIN_TOOLTIP,
  NOT_PAIRED_YEN_PIP_MULTIPLIER,
  NUMBER_VALIDATION_REG_ALLOW_NEGATIVE,
  OPTIONS_COUNTER_TYPE,
  PAIRED_YEN_PIP_MULTIPLIER,
  RECOMMENDED_MARGIN_LABEL,
  REQUIRED_MARGIN_LABEL,
  REQUIRED_MARGIN_TOOLTIP,
  TRADE_METHODS,
  VALIDATION_ERROR_EMPTY_FIELD,
  VALIDATION_ERROR_INVALID_VALUE,
  CHART_RESOLUTION_MAIN,
  DEFAULT_SERVICE_ID,
  CFD,
} from '../../constants';
import {
  BASE_PRICE_MAX_ETF_MULTIPLIER,
  BASE_PRICE_MAX_MULTIPLIER,
  BASE_PRICE_MIN_MULTIPLIER,
  BUILDER_EXCHANGE_TYPE_TO_SERVICE_ID,
  BUILDER_ORDER_CONFIGURATION_OPTIONS,
  BUILDER_ORDER_CONFIGURATION_VALIDATED_OPTIONS,
  BUILDER_ORDER_TYPES,
  BUILDER_ORDER_TYPES_NAME_BY_ID,
  BUILDER_REQUIRED_MARGIN_VALIDATION_MESSAGE,
  CFD_MIN_VALUE,
  COMMON_PIPS_MAX_VALUE,
  COMMON_PIPS_MIN_VALUE,
  DEFAULT_ORDER_SETS_VALUE,
  DEFAULT_VALUE_ETF_CFD_PRECISION,
  ENTRY_PRICE_VALUES,
  ETF_JPY_PRICE_MIN,
  ETF_NOT_JPY_PRICE_MIN,
  ITEMS_COUNT_MAX_VALUE,
  ITEMS_COUNT_MIN_VALUE,
  MAX_ORDERS_COUNT,
  ORDER_NAME_MAX_LENGTH,
  ORDER_SETTINGS_ADDING_ERROR_INFO,
  PROFIT_MARGIN_ETF_CFD_MULTIPLIER,
  PROFIT_MARGIN_FX_MULTIPLIER,
  QUANTITY_MAX_VALUE,
  RANGE_ETF_JPY_SPREAD_STEP,
  RANGE_ETF_NOT_JPY_SPREAD_STEP,
  RANGE_SPREAD_MIN_VALUE,
  STOP_LOSS_ETF_CFD_MAX_MULTIPLIER,
  STOP_LOSS_MAX_VALUE,
  STRATEGY_SETS_MIN_VALUE,
  TECH_START_PRICE_LITERAL,
} from '../../constants/builder';
import {
  addBuilderLogicGroup,
  addToCartBuilderRequest,
  addToPortfolioBuilderRequest,
  changeBuilderMultiOrderOption,
  changeBuilderMultiOrderValidationErrors,
  changeBuilderSingleOrderOption,
  changeBuilderSingleOrderValidationErrors,
  getBuilderChartDataRequest,
  resetBuilderOrdersTables,
  hideProfitlossChart,
  showProfitlossChart,
  changeTechBuilderMultiOrderOption,
  addTechBuilderLogicGroup,
  addToPortfolioTechBuilderRequest,
  addToCartTechBuilderRequest,
  resetBuilderSimulationData,
} from '../../redux/actions/builderActions';
import { openErrorInfoModal } from '../../redux/actions/errorActions';
import { sendNotificationSuccess } from '../../redux/actions/notificationActions';
import { decimalRounding } from '../builder';
import {
  calculateMaxItemForCart,
  formatNumberToDisplayedString,
  getAPQuantityStep,
  getBuilderRoundedOptionValue,
  getDateSolidString,
  getServiceQuantityUnit,
  checkIsWebApp,
} from '../index';
import { useInstrumentSettings, useSummaryInfoByServiceId } from './index';
import { changeConfigOption, getTechnicalBuilderDataRequest } from '../../redux/tech';
import { getCurrencyUnit, getPipsLabel, roundUpBy1000 } from '../../utils';
import { getApGroupRequest } from '../../redux/actions/portfolioActions';
import { useCalculatingMarginByStrategyList, useInstrumentOptions } from '../../hooks';

// eslint-disable-next-line
export const useGetBuilderServiceId = () => {
  const exchangeType = useSelector((state) => state.builder.exchangeType);
  return BUILDER_EXCHANGE_TYPE_TO_SERVICE_ID[exchangeType] ?? DEFAULT_SERVICE_ID;
};

export const useBuilderSingleOption = ({ fieldName, precision = null, validate = null }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.builder.singleOrder[fieldName]);

  const changeValue = useCallback(
    (newValue) => {
      const roundedValue = getBuilderRoundedOptionValue(newValue, precision);
      if (validate) {
        const { isValid, errorMessage } = validate(roundedValue);
        dispatch(changeBuilderSingleOrderValidationErrors({ fieldName, errorMessage, hasValidationError: !isValid }));
      }
      dispatch(
        changeBuilderSingleOrderOption({
          fieldName,
          value: roundedValue,
        }),
      );
    },
    [dispatch, fieldName, precision, validate],
  );

  return [value, changeValue];
};

export const useBuilderMultiOption = ({ fieldName, precision = null, validate = null }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.builder.multiOrder[fieldName]);

  const changeValue = useCallback(
    (newValue) => {
      const roundedValue = getBuilderRoundedOptionValue(newValue, precision);
      if (validate) {
        const { isValid, errorMessage } = validate(roundedValue);
        dispatch(changeBuilderMultiOrderValidationErrors({ fieldName, errorMessage, hasValidationError: !isValid }));
      }
      dispatch(
        changeBuilderMultiOrderOption({
          fieldName,
          value: roundedValue,
        }),
      );
    },
    [dispatch, fieldName, precision, validate],
  );

  return [value, changeValue];
};

export const useTechBuilderOption = ({ fieldName, precision = null, validate = null }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.builder.techOrder[fieldName]);

  const changeValue = useCallback(
    (newValue) => {
      const roundedValue = getBuilderRoundedOptionValue(newValue, precision);
      if (validate) {
        const { isValid, errorMessage } = validate(roundedValue);
        dispatch(changeBuilderMultiOrderValidationErrors({ fieldName, errorMessage, hasValidationError: !isValid }));
      }
      dispatch(
        changeTechBuilderMultiOrderOption({
          fieldName,
          value: roundedValue,
        }),
      );
    },
    [dispatch, fieldName, precision, validate],
  );

  return [value, changeValue];
};

export const useTechConfigOption = ({ fieldName, precision = null, validate = null }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.tech.config[fieldName]);

  const changeValue = useCallback(
    (newValue) => {
      const roundedValue = getBuilderRoundedOptionValue(newValue, precision);
      if (validate) {
        const { isValid, errorMessage } = validate(roundedValue);
        dispatch(changeBuilderMultiOrderValidationErrors({ fieldName, errorMessage, hasValidationError: !isValid }));
      }

      dispatch(changeConfigOption({ key: fieldName, value: roundedValue }));
    },
    [dispatch, fieldName, precision, validate],
  );

  return [value, changeValue];
};

export const usePipConversion = (currencyPair) => {
  const serviceId = useGetBuilderServiceId();

  return useMemo(() => {
    if (serviceId === FX) {
      return currencyPair.endsWith(COUNTRY_TYPE.JPY) ? PAIRED_YEN_PIP_MULTIPLIER : NOT_PAIRED_YEN_PIP_MULTIPLIER;
    }
    return 1;
  }, [currencyPair, serviceId]);
};

export const useBuilderInstrumentSettings = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);

  return useInstrumentSettings(instrumentId, TRADE_METHODS.AP.ID);
};

export const useBuilderPricePrecision = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const pipConversion = usePipConversion(instrumentId);
  const { pricePrecision: initialPrecision = 0.01 } = useBuilderInstrumentSettings();

  const pricePrecision = useMemo(() => {
    const logResult = Math.log10(initialPrecision);

    return (logResult === 0 ? 0 : -logResult) ?? 0;
  }, [initialPrecision]);
  const pipsPrecision = useMemo(() => {
    const pricePipsConversion = Number(Decimal.div(initialPrecision, pipConversion));
    const logResult = Math.log10(pricePipsConversion);

    return (logResult === 0 ? 0 : -logResult) ?? 0;
  }, [initialPrecision, pipConversion]);

  const initialPipsPrecision = useMemo(() => 10 ** -pipsPrecision, [pipsPrecision]);

  return useMemo(
    () => ({
      pricePrecision,
      pipsPrecision,
      initialPrecision,
      initialPipsPrecision,
    }),
    [pricePrecision, pipsPrecision, initialPrecision, initialPipsPrecision],
  );
};

export const useBuilderAverageVolatility = () => {
  const chartData = useSelector((state) => state.builder.chartData);
  const { pricePrecision } = useBuilderPricePrecision();

  return useMemo(() => {
    if (!chartData.length) return 0;
    const priceDifferenceSum = chartData.reduce((sum, { high, low }) => {
      return Decimal.sub(high, low).add(sum);
    }, 0);
    return decimalRounding(Decimal.div(priceDifferenceSum, chartData.length), pricePrecision);
  }, [chartData, pricePrecision]);
};

export const useBuilderFluctuationRange = () => {
  const chartData = useSelector((state) => state.builder.chartData);
  const { pricePrecision } = useBuilderPricePrecision();

  return useMemo(() => {
    if (!chartData.length) return 0;
    const { maxHigh, minLow } = chartData.reduce(
      ({ maxHigh: currentMax, minLow: currentMin }, { high, low }) => {
        return { maxHigh: Decimal.max(currentMax, high), minLow: Decimal.min(currentMin, low) };
      },
      { maxHigh: Number.NEGATIVE_INFINITY, minLow: Number.POSITIVE_INFINITY },
    );
    return decimalRounding(Decimal.sub(maxHigh, minLow), pricePrecision);
  }, [chartData, pricePrecision]);
};

export const useBuilderBasePrice = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const { pricePrecision } = useBuilderPricePrecision();

  const orderType = useSelector((state) => state.builder.orderType);
  const askCloseBuilder = useSelector((state) => state.builder.askCloseRate) ?? '';
  const bidCloseBuilder = useSelector((state) => state.builder.bidCloseRate) ?? '';
  const { askClose: askCloseCurrencies, bidClose: bidCloseCurrencies } =
    useSelector((state) => state.currencies.rates[instrumentId]) ?? {};
  let askClose;
  let bidClose;
  if (orderType === BUILDER_ORDER_TYPES.TECH.ID) {
    askClose = askCloseBuilder;
    bidClose = bidCloseBuilder;
  } else {
    askClose = askCloseCurrencies;
    bidClose = bidCloseCurrencies;
  }
  return useMemo(() => {
    if (!askClose || !bidClose) return 0;

    return decimalRounding(Decimal.add(askClose, bidClose).div(2), pricePrecision);
  }, [askClose, bidClose, pricePrecision]);
};

export const useBuilderBasePriceBySide = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const { pricePrecision } = useBuilderPricePrecision();
  const rate = useSelector((state) => state.currencies.rates[instrumentId]);
  const { askClose, bidClose } = rate ?? {};

  const orderType = useSelector((state) => state.builder.orderType);
  const isSingle = useMemo(() => orderType === BUILDER_ORDER_TYPES.SINGLE.ID, [orderType]);

  const selectedSellBuyId = useSelector(
    (state) => state.builder[isSingle ? 'singleOrder' : 'multiOrder'].selectedSellBuyId,
  );

  const isBuy = useMemo(() => Number(selectedSellBuyId) === BUY_SELL_MAIN.BUY.ID, [selectedSellBuyId]);
  return useMemo(
    () => decimalRounding(new Decimal((isBuy ? askClose : bidClose) ?? 0), pricePrecision),
    [isBuy, askClose, bidClose, pricePrecision],
  );
};

export const useBuilderMultiBasePriceForSide = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const { pricePrecision } = useBuilderPricePrecision();
  const rate = useSelector((state) => state.currencies.rates[instrumentId]);
  const { askClose, bidClose } = rate ?? {};

  return useMemo(
    () => ({
      basePriceAsk: decimalRounding(new Decimal(askClose ?? 0), pricePrecision),
      basePriceBid: decimalRounding(new Decimal(bidClose ?? 0), pricePrecision),
    }),
    [askClose, bidClose, pricePrecision],
  );
};

export const useBuilderSingleEntryPriceParsedValue = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const { entryPriceTypeId, entryPriceValue, entryPricePipsValue } = useSelector((state) => state.builder.singleOrder);
  const basePrice = useBuilderBasePriceBySide();
  const pipConversion = usePipConversion(instrumentId);
  const { pricePrecision } = useBuilderPricePrecision();
  return useMemo(() => {
    if (Number(entryPriceTypeId) === ENTRY_PRICE_VALUES.PREVIOUS_DAY.ID) {
      if (!Number(entryPricePipsValue)) {
        return basePrice;
      }
      return decimalRounding(Decimal.mul(entryPricePipsValue, pipConversion).add(basePrice), pricePrecision);
    }
    if (!Number(entryPriceValue)) return 0;
    return entryPriceValue;
  }, [entryPriceTypeId, entryPriceValue, basePrice, pipConversion, entryPricePipsValue, pricePrecision]);
};

export const useBuilderMultiEntryPriceParsedValues = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const { selectedSellBuyId, entryPriceTypeId, entryPriceValue, entryPricePipsValue, rangeSpread, itemsCount } =
    useSelector((state) => state.builder.multiOrder);
  const isBuy = useMemo(() => Number(selectedSellBuyId) === BUY_SELL_MAIN.BUY.ID, [selectedSellBuyId]);
  const pipConversion = usePipConversion(instrumentId);
  const basePrice = useBuilderBasePriceBySide();
  const { pricePrecision, pipsPrecision } = useBuilderPricePrecision();
  const orderSpace = useMemo(() => {
    if (!Number(rangeSpread) || !Number(itemsCount)) return 0;
    return decimalRounding(Decimal.div(rangeSpread, itemsCount), pipsPrecision);
  }, [rangeSpread, itemsCount, pipsPrecision]);
  const orderSpaceConversion = Number(Decimal.mul(orderSpace, 0).mul(pipConversion));
  const entryPrice1InitialValue = useMemo(() => {
    if (Number(entryPriceTypeId) === ENTRY_PRICE_VALUES.PREVIOUS_DAY.ID) {
      if (!Number(entryPricePipsValue)) return basePrice;
      return Number(Decimal.mul(entryPricePipsValue, pipConversion).add(basePrice));
    }
    if (!Number(entryPriceValue)) return 0;
    return entryPriceValue;
  }, [entryPriceTypeId, basePrice, entryPriceValue, entryPricePipsValue, pipConversion]);

  const entryPrice1 = isBuy
    ? decimalRounding(Decimal.sub(entryPrice1InitialValue, orderSpaceConversion), pricePrecision)
    : decimalRounding(Decimal.add(entryPrice1InitialValue, orderSpaceConversion), pricePrecision);

  const rangeSpreadBuyGroupValue = entryPrice1 - rangeSpread * pipConversion;
  const rangeSpreadSellGroupValue = entryPrice1 + rangeSpread * pipConversion;
  const rangeSpreadPrice = isBuy ? rangeSpreadBuyGroupValue : rangeSpreadSellGroupValue;
  return [entryPrice1, rangeSpreadPrice];
};

export const useBuilderAddMultiOrderSettings = (submitCallback) => {
  const dispatch = useDispatch();
  const {
    selectedSellBuyId,
    amount,
    entryPriceTypeId,
    entryPriceValue,
    entryPricePipsValue,
    ocoIsChecked,
    profitMargin,
    followValueIsChecked,
    followValue,
    counterValueIsChecked,
    counterValue,
    rangeSpread,
    itemsCount,
    stopLossSpreadIsChecked,
    stopLossSpread,
    counterIsFixed,
  } = useSelector((state) => state.builder.multiOrder);
  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);
  const basePrice = useBuilderBasePriceBySide();
  const activeCurrency = useSelector((state) => state.builder.activeCurrency);
  const pipConversion = usePipConversion(activeCurrency);
  const { pricePrecision, pipsPrecision } = useBuilderPricePrecision();
  const instrumentsOptions = useInstrumentOptions();
  const serviceId = useGetBuilderServiceId();
  const instrumentShortName = instrumentsOptions[serviceId]?.find(
    (instrument) => instrument.value === activeCurrency,
  )?.label;
  const instrumentId = serviceId === FX ? activeCurrency : instrumentShortName;
  const realInstrumentId = activeCurrency;

  const isBuy = useMemo(() => Number(selectedSellBuyId) === BUY_SELL_MAIN.BUY.ID, [selectedSellBuyId]);
  const entryPrice1InitialValue = useMemo(() => {
    if (Number(entryPriceTypeId) === ENTRY_PRICE_VALUES.PREVIOUS_DAY.ID) {
      if (!Number(entryPricePipsValue)) return basePrice;
      return Number(Decimal.mul(entryPricePipsValue, pipConversion).add(basePrice));
    }
    if (!Number(entryPriceValue)) return 0;
    return entryPriceValue;
  }, [entryPriceTypeId, basePrice, entryPriceValue, entryPricePipsValue, pipConversion]);
  const orderSpace = useMemo(() => {
    if (!Number(rangeSpread) || !Number(itemsCount)) return 0;
    return decimalRounding(Decimal.div(rangeSpread, itemsCount), pipsPrecision);
  }, [rangeSpread, itemsCount, pipsPrecision]);

  return useCallback(() => {
    const logicGroupId = uuid();
    const orderSettings = [];
    const convertedItemsCount = Number(itemsCount);
    if (submitCallback) {
      submitCallback(orderSettingsList.length + convertedItemsCount <= MAX_ORDERS_COUNT);
    }
    if (orderSettingsList.length + convertedItemsCount > MAX_ORDERS_COUNT) {
      dispatch(openErrorInfoModal({ message: ORDER_SETTINGS_ADDING_ERROR_INFO, title: '', buttonText: '閉じる' }));
    } else {
      if (convertedItemsCount) {
        for (let orderNumber = 0; orderNumber < convertedItemsCount; orderNumber += 1) {
          const orderSpaceConversion = Number(Decimal.mul(orderSpace, orderNumber).mul(pipConversion));
          const profitMarginConversion = Number(Decimal.mul(profitMargin, pipConversion));
          const counterValueConversion = Number(Decimal.mul(counterValue || 0, pipConversion));
          const entryPrice1 = isBuy
            ? decimalRounding(Decimal.sub(entryPrice1InitialValue, orderSpaceConversion), pricePrecision)
            : decimalRounding(Decimal.add(entryPrice1InitialValue, orderSpaceConversion), pricePrecision);
          const counterFixedValue = isBuy
            ? decimalRounding(
                Decimal.add(entryPrice1, counterValueConversion).add(profitMarginConversion),
                pricePrecision,
              )
            : decimalRounding(
                Decimal.add(entryPrice1, counterValueConversion).sub(profitMarginConversion),
                pricePrecision,
              );
          const currentRangeSpread = Number(Decimal.mul(rangeSpread, pipConversion));
          const entryPrice2Value = isBuy
            ? decimalRounding(Decimal.add(entryPrice1, currentRangeSpread), pricePrecision)
            : decimalRounding(Decimal.sub(entryPrice1, currentRangeSpread), pricePrecision);
          const entryPrice2 = ocoIsChecked ? entryPrice2Value : null;
          orderSettings.push({
            instrumentId,
            realInstrumentId,
            buySell: selectedSellBuyId,
            amount,
            entryPriceTypeId,
            entryPricePipsValue,
            entryPrice1,
            entryPrice2,
            profitMargin,
            stopLossRange: stopLossSpreadIsChecked ? stopLossSpread : null,
            follow: followValueIsChecked ? followValue : null,
            counter: counterValueIsChecked && !counterIsFixed ? counterValue : null,
            counterPrice: counterValueIsChecked && counterIsFixed ? counterFixedValue : null,
            logicGroupId,
          });
        }
      }
      const logicGroup = {
        instrumentId,
        buySell: selectedSellBuyId,
        amount,
        itemsCount: Number(itemsCount),
        rangeSpread,
        entryPriceType: entryPriceTypeId,
        ocoIsChecked,
        profitMargin,
        stopLossRange: stopLossSpreadIsChecked ? stopLossSpread : null,
        follow: followValueIsChecked ? followValue : null,
        counter: counterValueIsChecked ? counterValue : null,
        counterIsFixed,
        id: logicGroupId,
      };

      dispatch(addBuilderLogicGroup({ orderSettings, logicGroup }));
    }
  }, [
    itemsCount,
    submitCallback,
    orderSettingsList.length,
    dispatch,
    instrumentId,
    realInstrumentId,
    selectedSellBuyId,
    amount,
    rangeSpread,
    entryPriceTypeId,
    ocoIsChecked,
    profitMargin,
    stopLossSpreadIsChecked,
    stopLossSpread,
    followValueIsChecked,
    followValue,
    counterValueIsChecked,
    counterValue,
    counterIsFixed,
    orderSpace,
    pipConversion,
    isBuy,
    entryPrice1InitialValue,
    pricePrecision,
    entryPricePipsValue,
  ]);
};

export const useTechBuilderAddMultiOrderSettings = (submitCallback) => {
  const dispatch = useDispatch();
  const { indicator1, indicator2, barType, isAllSettlement, counterIsFixed } = useSelector(
    (state) => state.builder.techOrder,
  );
  const {
    amount,
    selectedSellBuyId,
    profitMargin,
    followValueIsChecked,
    followValue,
    counterValueIsChecked,
    counterValue,
    rangeSpread,
    itemsCount,
    stopLossSpreadIsChecked,
    stopLossSpread,
  } = useSelector((state) => state.builder.multiOrder);
  const activeCurrency = useSelector((state) => state.builder.activeCurrency);
  const pipConversion = usePipConversion(activeCurrency);
  const { pricePrecision, pipsPrecision } = useBuilderPricePrecision();
  const instrumentsOptions = useInstrumentOptions();
  const serviceId = useGetBuilderServiceId();
  const instrumentShortName = instrumentsOptions[serviceId]?.find(
    (instrument) => instrument.value === activeCurrency,
  )?.label;
  const instrumentId = serviceId === FX ? activeCurrency : instrumentShortName;

  const isBuy = useMemo(() => Number(selectedSellBuyId) === BUY_SELL_MAIN.BUY.ID, [selectedSellBuyId]);
  const entryPricePipsValue = useMemo(() => {
    if (!Number(rangeSpread)) return 0;
    return decimalRounding(Decimal.div(rangeSpread, 2), pipsPrecision);
  }, [rangeSpread, pipsPrecision]);
  const entryPriceRelative = useMemo(
    () => Number(Decimal.mul(entryPricePipsValue, pipConversion)),
    [entryPricePipsValue, pipConversion],
  );
  const orderSpace = useMemo(() => {
    if (!Number(rangeSpread) || !Number(itemsCount)) return 0;
    return decimalRounding(Decimal.div(rangeSpread, itemsCount), pipsPrecision);
  }, [rangeSpread, itemsCount, pipsPrecision]);
  const displayValue = (price) => {
    return price >= 0 ? `スタート価格 + ${price}` : `スタート価格 ${price}`;
  };

  return useCallback(() => {
    const logicGroupId = uuid();
    const orderSettings = [];
    const convertedItemsCount = Number(itemsCount);

    if (convertedItemsCount > MAX_ORDERS_COUNT) {
      dispatch(openErrorInfoModal({ message: ORDER_SETTINGS_ADDING_ERROR_INFO, title: '', buttonText: '閉じる' }));
      return;
    }
    if (convertedItemsCount) {
      for (let orderNumber = 0; orderNumber < convertedItemsCount; orderNumber += 1) {
        const orderSpaceConversion = Number(Decimal.mul(orderSpace, orderNumber).mul(pipConversion));
        const profitMarginConversion = Number(Decimal.mul(profitMargin, pipConversion));
        const counterValueConversion = Number(Decimal.mul(counterValue || 0, pipConversion));
        const entryPrice1Relative = isBuy
          ? decimalRounding(Decimal.sub(entryPriceRelative, orderSpaceConversion), pricePrecision)
          : decimalRounding(Decimal.sub(0, Decimal.sub(entryPriceRelative, orderSpaceConversion)), pricePrecision);
        const entryPrice1 = displayValue(entryPrice1Relative);
        const counterFixedValueRelative = isBuy
          ? decimalRounding(
              Decimal.add(entryPrice1Relative, counterValueConversion).add(profitMarginConversion),
              pricePrecision,
            )
          : decimalRounding(
              Decimal.add(entryPrice1Relative, counterValueConversion).sub(profitMarginConversion),
              pricePrecision,
            );
        const counterFixedValue = displayValue(counterFixedValueRelative);
        orderSettings.push({
          instrumentId,
          buySell: selectedSellBuyId,
          amount,
          entryPrice1,
          entryPrice1Relative,
          profitMargin,
          stopLossRange: stopLossSpreadIsChecked ? stopLossSpread : null,
          follow: followValueIsChecked ? followValue : null,
          counter: counterValueIsChecked ? counterValue : null,
          counterPrice: counterValueIsChecked && counterIsFixed ? counterFixedValue : null,
          counterFixedValueRelative,
          logicGroupId,
        });
      }
    }
    const logicGroup = {
      indicator1,
      indicator2,
      barType,
      isAllSettlement,
      instrumentId,
      buySell: selectedSellBuyId,
      amount,
      itemsCount,
      rangeSpread,
      profitMargin,
      stopLossRange: stopLossSpreadIsChecked ? stopLossSpread : null,
      follow: followValueIsChecked ? followValue : null,
      counter: counterValueIsChecked ? counterValue : null,
      counterIsFixed,
      id: logicGroupId,
    };
    dispatch(addTechBuilderLogicGroup({ orderSettings, logicGroup }));
    if (submitCallback) {
      submitCallback(convertedItemsCount <= MAX_ORDERS_COUNT);
    }
  }, [
    submitCallback,
    dispatch,
    indicator1,
    indicator2,
    barType,
    isAllSettlement,
    itemsCount,
    isBuy,
    entryPriceRelative,
    orderSpace,
    counterValue,
    profitMargin,
    counterIsFixed,
    counterValueIsChecked,
    instrumentId,
    selectedSellBuyId,
    amount,
    rangeSpread,
    stopLossSpreadIsChecked,
    stopLossSpread,
    followValueIsChecked,
    followValue,
    pipConversion,
    pricePrecision,
  ]);
};

export const useBuilderProfitMargin = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const averageVolatility = useBuilderAverageVolatility();
  const pipConversion = usePipConversion(instrumentId);
  const { pricePrecision, pipsPrecision } = useBuilderPricePrecision();
  const serviceId = useGetBuilderServiceId();
  const precision = useMemo(() => {
    if (serviceId === FX) {
      return pricePrecision - 1;
    }
    if (serviceId === ETF) {
      return pricePrecision;
    }
    return DEFAULT_VALUE_ETF_CFD_PRECISION;
  }, [serviceId, pricePrecision]);

  const profitMargin = useMemo(() => {
    const multiplier = serviceId === FX ? PROFIT_MARGIN_FX_MULTIPLIER : PROFIT_MARGIN_ETF_CFD_MULTIPLIER;
    return decimalRounding(Decimal.div(averageVolatility, multiplier), precision);
  }, [averageVolatility, serviceId, precision]);
  const profitMarginPips = useMemo(
    () => decimalRounding(Decimal.div(profitMargin, pipConversion), pipsPrecision),
    [profitMargin, pipConversion, pipsPrecision],
  );

  return useMemo(
    () => ({
      profitMargin,
      profitMarginPips,
    }),
    [profitMargin, profitMarginPips],
  );
};

export const useBuilderSpreadRangePipsPrecison = () => {
  const serviceId = useGetBuilderServiceId();
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  if (serviceId === CFD) {
    return 0;
  }
  if (instrumentId.endsWith('JPY') || serviceId === FX) {
    return -1;
  }
  return 1;
};

export const useBuilderRangeSpread = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const fluctuationRange = useBuilderFluctuationRange();
  const pipConversion = usePipConversion(instrumentId);
  const pipsPrecision = useBuilderSpreadRangePipsPrecison();
  return useMemo(
    () => decimalRounding(Decimal.div(fluctuationRange, pipConversion), pipsPrecision),
    [fluctuationRange, pipConversion, pipsPrecision],
  );
};

export const useBuilderItemsCount = () => {
  const { profitMarginPips } = useBuilderProfitMargin();
  const rangeSpread = useBuilderRangeSpread();

  return useMemo(() => {
    if (!rangeSpread || !profitMarginPips) return 0;
    return Number(Decimal.div(rangeSpread, profitMarginPips).floor());
  }, [rangeSpread, profitMarginPips]);
};

export const useBuilderSingleEntryPriceValue = (side) => {
  const basePrice = useBuilderBasePrice();
  const { profitMargin } = useBuilderProfitMargin();
  const { pricePrecision } = useBuilderPricePrecision();

  return useMemo(() => {
    if (Number(side) === BUY_SELL_MAIN.BUY.ID)
      return decimalRounding(Decimal.sub(basePrice, profitMargin), pricePrecision);
    return decimalRounding(Decimal.add(basePrice, profitMargin), pricePrecision);
  }, [side, basePrice, profitMargin, pricePrecision]);
};

export const useBuilderValuePricePrecision = () => {
  const serviceId = useGetBuilderServiceId();
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  if (serviceId === CFD) {
    return 0;
  }
  if (instrumentId.endsWith('JPY') && serviceId === ETF) {
    return -1;
  }
  if ((instrumentId.endsWith('JPY') && serviceId === FX) || (instrumentId.endsWith('USD') && serviceId === ETF)) {
    return 0;
  }
  if (instrumentId.endsWith('USD') && serviceId === ETF) {
    return 1;
  }
  return 2;
};

export const useBuilderMultiEntryPriceValue = (side) => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const basePrice = useBuilderBasePrice();
  const pricePrecision = useBuilderValuePricePrecision();
  const { profitMarginPips } = useBuilderProfitMargin();
  const itemsCount = useBuilderItemsCount();
  const pipConversion = usePipConversion(instrumentId);
  const profitCountConversion = useMemo(
    () => Number(Decimal.mul(profitMarginPips, itemsCount).div(2).mul(pipConversion)),
    [profitMarginPips, itemsCount, pipConversion],
  );

  return useMemo(() => {
    if (Number(side) === BUY_SELL_MAIN.BUY.ID) {
      return decimalRounding(Decimal.add(basePrice, profitCountConversion), pricePrecision);
    }

    const tempResult = decimalRounding(Decimal.sub(basePrice, profitCountConversion), pricePrecision);
    if (tempResult >= 0) {
      return tempResult;
    }
    return decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), pricePrecision);
  }, [side, basePrice, profitCountConversion, pricePrecision]);
};

export const useBuilderMultiEntryPricePipsValue = (side) => {
  const { profitMarginPips } = useBuilderProfitMargin();
  const { pipsPrecision } = useBuilderPricePrecision();
  const serviceId = useGetBuilderServiceId();
  const itemsCount = useBuilderItemsCount();
  const precision = serviceId === CFD ? DEFAULT_VALUE_ETF_CFD_PRECISION : pipsPrecision;
  const profitValue = useMemo(
    () => decimalRounding(Decimal.mul(profitMarginPips, itemsCount).div(2), precision),
    [profitMarginPips, itemsCount, precision],
  );

  return useMemo(() => {
    if (Number(side) === BUY_SELL_MAIN.BUY.ID) return profitValue;
    return Number(Decimal.sub(0, profitValue));
  }, [side, profitValue]);
};

export const useBuilderSingleOcoValue = (side) => {
  const basePrice = useBuilderBasePrice();
  const { profitMargin } = useBuilderProfitMargin();
  const { pricePrecision } = useBuilderPricePrecision();

  return useMemo(() => {
    if (Number(side) === BUY_SELL_MAIN.BUY.ID)
      return decimalRounding(Decimal.add(basePrice, profitMargin), pricePrecision);
    return decimalRounding(Decimal.sub(basePrice, profitMargin), pricePrecision);
  }, [side, basePrice, profitMargin, pricePrecision]);
};

export const useBuilderSingleStopLossSpread = () => {
  const { profitMarginPips } = useBuilderProfitMargin();

  return useMemo(() => {
    const convertedProfitMargin = Decimal.mul(profitMarginPips, 10);
    return Number(Decimal.sub(0, convertedProfitMargin));
  }, [profitMarginPips]);
};

export const useBuilderMultiStopLossSpread = () => {
  const { profitMarginPips } = useBuilderProfitMargin();
  const itemsCount = useBuilderItemsCount();
  const basePrice = useBuilderBasePrice();
  const serviceId = useGetBuilderServiceId();
  const { pricePrecision } = useBuilderPricePrecision();
  const precision = serviceId === CFD ? DEFAULT_VALUE_ETF_CFD_PRECISION : pricePrecision;

  return useMemo(() => {
    if (serviceId === FX) {
      return Number(Decimal.sub(0, profitMarginPips).mul(itemsCount));
    }
    return decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER).neg(), precision);
  }, [profitMarginPips, itemsCount, serviceId, basePrice, precision]);
};

export const useBuilderSingleFollow = (side) => {
  const { profitMarginPips } = useBuilderProfitMargin();

  return useMemo(() => {
    if (Number(side) === BUY_SELL_MAIN.BUY.ID) return profitMarginPips;
    return Number(Decimal.sub(0, profitMarginPips));
  }, [side, profitMarginPips]);
};

export const useBuilderMultiFollow = (side) => {
  const { profitMarginPips } = useBuilderProfitMargin();
  const itemsCount = useBuilderItemsCount();
  const { pipsPrecision } = useBuilderPricePrecision();

  return useMemo(() => {
    if (Number(side) === BUY_SELL_MAIN.BUY.ID) {
      return decimalRounding(Decimal.mul(profitMarginPips, itemsCount).sub(profitMarginPips), pipsPrecision);
    }
    return -decimalRounding(Decimal.mul(profitMarginPips, itemsCount).sub(profitMarginPips), pipsPrecision);
  }, [profitMarginPips, itemsCount, side, pipsPrecision]);
};

export const useBuilderCounter = (side) => {
  const { profitMarginPips } = useBuilderProfitMargin();

  return useMemo(() => {
    if (Number(side) === BUY_SELL_MAIN.BUY.ID) return Number(Decimal.sub(0, profitMarginPips));
    return profitMarginPips;
  }, [side, profitMarginPips]);
};

export const useBuilderCounterPrice = (side) => {
  const { profitMargin } = useBuilderProfitMargin();
  const basePrice = useBuilderBasePrice();
  const { pricePrecision } = useBuilderPricePrecision();

  return useMemo(() => {
    if (Number(side) === BUY_SELL_MAIN.BUY.ID)
      return decimalRounding(Decimal.sub(basePrice, profitMargin), pricePrecision);
    return decimalRounding(Decimal.add(basePrice, profitMargin), pricePrecision);
  }, [side, basePrice, profitMargin, pricePrecision]);
};

export const useTechBuilderFollow = (side) => {
  const { profitMarginPips } = useBuilderProfitMargin();

  return useMemo(() => {
    if (Number(side) === BUY_SELL_MAIN.SELL.ID) return Number(Decimal.sub(0, profitMarginPips));
    return profitMarginPips;
  }, [side, profitMarginPips]);
};

export const useBuilderSingleDefaultSettings = () => {
  const defaultSelectedSellBuyId = useSelector((state) => state.builder.singleOrder.selectedSellBuyId);

  const { profitMarginPips: defaultProfitMargin } = useBuilderProfitMargin();
  const defaultStopLossSpread = useBuilderSingleStopLossSpread();
  const defaultEntryPriceValue = useBuilderSingleEntryPriceValue(defaultSelectedSellBuyId);
  const defaultOcoValue = useBuilderSingleOcoValue(defaultSelectedSellBuyId);
  const defaultFollow = useBuilderSingleFollow(defaultSelectedSellBuyId);
  const defaultCounterValue = useBuilderCounter(defaultSelectedSellBuyId);
  const defaultCounterPriceValue = useBuilderCounterPrice(defaultSelectedSellBuyId);

  return useMemo(
    () => ({
      defaultSelectedSellBuyId,
      defaultEntryPriceValue,
      defaultOcoValue,
      defaultProfitMargin,
      defaultStopLossSpread,
      defaultFollow,
      defaultCounterValue,
      defaultCounterPriceValue,
    }),
    [
      defaultSelectedSellBuyId,
      defaultProfitMargin,
      defaultEntryPriceValue,
      defaultOcoValue,
      defaultStopLossSpread,
      defaultFollow,
      defaultCounterValue,
      defaultCounterPriceValue,
    ],
  );
};

export const useBuilderMultiDefaultSettings = () => {
  const { allowBuyFlg, allowSellFlg } = useBuilderInstrumentSettings();
  const defaultSelectedSellBuyId = useSelector((state) => state.builder.multiOrder.selectedSellBuyId);
  const sellBuyIsNotSelectable = useMemo(() => !allowBuyFlg || !allowSellFlg, [allowBuyFlg, allowSellFlg]);
  const defaultRangeSpread = useBuilderRangeSpread();
  const { profitMarginPips: defaultProfitMargin } = useBuilderProfitMargin();
  const defaultStopLossSpread = useBuilderMultiStopLossSpread();
  const defaultItemsCount = useBuilderItemsCount();
  const defaultEntryPriceValue = useBuilderMultiEntryPriceValue(defaultSelectedSellBuyId);
  const defaultEntryPricePipsValue = useBuilderMultiEntryPricePipsValue(defaultSelectedSellBuyId);
  const defaultFollow = useBuilderMultiFollow(defaultSelectedSellBuyId);
  const defaultCounterValue = useBuilderCounter(defaultSelectedSellBuyId);
  const defaultCounterPriceValue = useBuilderCounterPrice(defaultSelectedSellBuyId);

  return useMemo(
    () => ({
      defaultSelectedSellBuyId,
      sellBuyIsNotSelectable,
      defaultRangeSpread,
      defaultItemsCount,
      defaultEntryPriceValue,
      defaultEntryPricePipsValue,
      defaultProfitMargin,
      defaultStopLossSpread,
      defaultFollow,
      defaultCounterValue,
      defaultCounterPriceValue,
    }),
    [
      defaultSelectedSellBuyId,
      sellBuyIsNotSelectable,
      defaultRangeSpread,
      defaultItemsCount,
      defaultEntryPriceValue,
      defaultEntryPricePipsValue,
      defaultProfitMargin,
      defaultStopLossSpread,
      defaultFollow,
      defaultCounterValue,
      defaultCounterPriceValue,
    ],
  );
};

// TODO matsuzaki100983
// 後ほど修正_テクビ設定の初期化
export const useBuilderTechDefaultSettings = () => {
  const { allowBuyFlg, allowSellFlg } = useBuilderInstrumentSettings();
  const defaultSelectedSellBuyId = useSelector((state) => state.builder.multiOrder.selectedSellBuyId);
  const sellBuyIsNotSelectable = useMemo(() => !allowBuyFlg || !allowSellFlg, [allowBuyFlg, allowSellFlg]);
  const defaultBarType = CHART_RESOLUTION_MAIN.DAYS_1.TV_ID;
  const defaultIndicator1 = INDICATORS_TYPES.MOVING_AVERAGE.VALUE;
  const defaultIndicator2 = '';
  const defaultSetIsAllSettlement = true;
  const defaultRangeSpread = useBuilderRangeSpread();
  const { profitMarginPips: defaultProfitMargin } = useBuilderProfitMargin();
  const defaultStopLossSpread = useBuilderMultiStopLossSpread();
  const defaultItemsCount = useBuilderItemsCount();
  const defaultFollowCheck = true;
  const defaultFollow = useTechBuilderFollow(defaultSelectedSellBuyId);
  const defaultCounterValue = useBuilderCounter(defaultSelectedSellBuyId);
  const defaultCounterPriceValue = useBuilderCounterPrice(defaultSelectedSellBuyId);
  const defaultCounterIsFixed = false;

  return useMemo(
    () => ({
      defaultSelectedSellBuyId,
      sellBuyIsNotSelectable,
      defaultBarType,
      defaultIndicator1,
      defaultIndicator2,
      defaultSetIsAllSettlement,
      defaultRangeSpread,
      defaultItemsCount,
      defaultProfitMargin,
      defaultStopLossSpread,
      defaultFollowCheck,
      defaultFollow,
      defaultCounterValue,
      defaultCounterPriceValue,
      defaultCounterIsFixed,
    }),
    [
      defaultSelectedSellBuyId,
      sellBuyIsNotSelectable,
      defaultBarType,
      defaultIndicator1,
      defaultIndicator2,
      defaultSetIsAllSettlement,
      defaultRangeSpread,
      defaultItemsCount,
      defaultProfitMargin,
      defaultStopLossSpread,
      defaultFollowCheck,
      defaultFollow,
      defaultCounterValue,
      defaultCounterPriceValue,
      defaultCounterIsFixed,
    ],
  );
};

export const useBuilderChangeCounterValue = (
  currentBasePrice,
  pipConversion,
  setCounterValue,
  setCounterPriceValue,
) => {
  const { pricePrecision } = useBuilderPricePrecision();

  return useCallback(
    (value) => {
      const convertedValue = Number(value) || 0;
      const counterPriceValue = decimalRounding(
        Decimal.mul(convertedValue, pipConversion).add(currentBasePrice),
        pricePrecision,
      );

      setCounterValue(value);
      setCounterPriceValue(counterPriceValue);
    },
    [currentBasePrice, pipConversion, setCounterValue, setCounterPriceValue, pricePrecision],
  );
};

export const useBuilderChangeCounterPriceValue = (
  currentBasePrice,
  pipConversion,
  setCounterValue,
  setCounterPriceValue,
) => {
  const { pipsPrecision } = useBuilderPricePrecision();

  return useCallback(
    (value) => {
      const convertedValue = Number(value) || 0;
      const counterValue = decimalRounding(
        Decimal.sub(convertedValue, currentBasePrice).div(pipConversion),
        pipsPrecision,
      );

      setCounterValue(counterValue);
      setCounterPriceValue(value);
    },
    [currentBasePrice, pipConversion, setCounterValue, setCounterPriceValue, pipsPrecision],
  );
};

export const useBuilderLogicName = (isTechBuilder) => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const instrumentShortName = useMemo(() => {
    if (instrumentId && instrumentList?.[instrumentId]) return instrumentList[instrumentId].shortName;
    return '';
  }, [instrumentId, instrumentList]);

  const builderLogicName = useMemo(() => {
    if (isTechBuilder) return `テクニカルビルダー_${instrumentShortName}`;
    return `ビルダー_${instrumentShortName}_${getDateSolidString()}`;
  }, [instrumentShortName, isTechBuilder]);

  return builderLogicName;
};

export const useGetBuilderOrderRequestData = () => {
  const orderSettings = useSelector((state) => state.builder.orderSettingsList);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const orderType = useSelector((state) => state.builder.orderType);
  const rangeSpread = useSelector((state) => state.builder.multiOrder.rangeSpread);
  const isSingle = useMemo(() => orderType === BUILDER_ORDER_TYPES.SINGLE.ID, [orderType]);
  const orderDefaultName = useBuilderLogicName();
  const pipConversion = usePipConversion(instrumentId);
  const { pipsPrecision } = useBuilderPricePrecision();
  const { basePriceAsk, basePriceBid } = useBuilderMultiBasePriceForSide();

  return useCallback(
    (name = '') => {
      const orderName = name ? `${name}_${BUILDER_ORDER_TYPES_NAME_BY_ID[orderType]}` : orderDefaultName;
      const itemList = orderSettings.map(
        (
          {
            buySell,
            amount,
            entryPrice1,
            entryPrice2,
            stopLossRange,
            profitMargin,
            follow,
            counter,
            counterPrice,
            entryPriceTypeId,
            entryPricePipsValue,
          },
          index,
        ) => {
          const number = index + 1;
          const orderNumber = number < 10 ? `0${number}` : number;
          const formatEntryPricePipsValue = (value) => {
            if (value !== null) {
              if (Number(value) >= 0) {
                return `startPrice+${value}`;
              }
              return `startPrice${value}`;
            }
            return null;
          };

          const isBuy = Number(buySell) === BUY_SELL_MAIN.BUY.ID;
          const convertMultiEntryPrice1Value = (value) => {
            return decimalRounding(
              Decimal.sub(value, isBuy ? basePriceAsk : basePriceBid).div(pipConversion),
              pipsPrecision,
            );
          };

          const convertMultiEntryPrice2Value = (value) => {
            if (entryPrice2 === null) return entryPrice2;
            return isBuy
              ? decimalRounding(Decimal.add(value, Number(rangeSpread)), pipsPrecision)
              : decimalRounding(Decimal.sub(value, Number(rangeSpread)), pipsPrecision);
          };

          const entryPrice1Modified =
            Number(entryPriceTypeId) === ENTRY_PRICE_VALUES.PREVIOUS_DAY.ID
              ? formatEntryPricePipsValue(isSingle ? entryPricePipsValue : convertMultiEntryPrice1Value(entryPrice1))
              : entryPrice1;
          const entryPrice2Modified =
            Number(entryPriceTypeId) === ENTRY_PRICE_VALUES.PREVIOUS_DAY.ID
              ? formatEntryPricePipsValue(
                  isSingle ? entryPrice2 : convertMultiEntryPrice2Value(convertMultiEntryPrice1Value(entryPrice1)), // eslint-disable-line
                )
              : entryPrice2;

          return {
            id: number,
            name: `${orderName}_${orderNumber}`,
            side: buySell,
            quantity: amount,
            sl: stopLossRange,
            tp: profitMargin,
            entryPrice1: entryPrice1Modified,
            entryPrice2: entryPrice2Modified,
            follow,
            counter,
            counterPrice,
          };
        },
      );

      return {
        name: orderName,
        instrumentId,
        itemList,
      };
    },
    [
      instrumentId,
      orderDefaultName,
      orderSettings,
      orderType,
      basePriceAsk,
      basePriceBid,
      isSingle,
      pipConversion,
      rangeSpread,
      pipsPrecision,
    ],
  );
};

export const useGetTechBuilderOrderRequestData = () => {
  const orderSettings = useSelector((state) => state.builder.orderSettingsList);
  const orderDefaultName = useBuilderLogicName(true);
  const logicGroupsList = useSelector((state) => state.builder.logicGroupsList);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();

  return useCallback(
    (name = '') => {
      const {
        indicator1,
        indicator2,
        isAllSettlement,
        barType,
        rangeSpread,
        buySell: buySellType,
        counterIsFixed,
      } = logicGroupsList[0];

      const orderName = name || orderDefaultName;
      const itemList = orderSettings.map(
        (
          {
            buySell,
            amount,
            entryPrice1Relative,
            stopLossRange,
            profitMargin,
            follow,
            counter,
            counterFixedValueRelative,
          },
          index,
        ) => {
          const number = index + 1;
          const orderNumber = number < 10 ? `0${number}` : number;
          const formatEntryPricePipsValue = (value) => {
            if (value !== null) {
              if (Number(value) >= 0) {
                return `basePrice+${value}`;
              }
              return `basePrice${value}`;
            }
            return null;
          };

          const entryPrice1Modified = formatEntryPricePipsValue(entryPrice1Relative);
          const counterPrice = formatEntryPricePipsValue(counterFixedValueRelative);

          return {
            id: number,
            name: `${orderName}_${orderNumber}`,
            side: buySell,
            quantity: Number(amount),
            sl: stopLossRange,
            tp: profitMargin,
            entryPrice1: entryPrice1Modified,
            entryPrice2: null,
            follow,
            counter,
            counterPrice: counterIsFixed && counter ? counterPrice : null,
          };
        },
      );
      const orderNums = itemList.length;
      const strategyInfo = {
        name: orderName,
        logicId: 400, // テクニカルビルダーのlogicId
        defaultSet: 1, // 設定項目がないため1固定
        instrumentId,
        itemList,
      };
      if (!logicGroupsList) return '';

      const indicatorIds = [];

      if (indicator1) {
        indicatorIds.push(INDICATOR_IDS[indicator1]);
      }
      if (indicator2) {
        indicatorIds.push(INDICATOR_IDS[indicator2]);
      }

      return {
        strategyInfo,
        serviceId,
        indicatorIds,
        isAutoSettle: isAllSettlement,
        buySellSignType: String(buySellType),
        chartType: CHART_RESOLUTION_VALUES[barType]?.ID,
        orderNums: Number(orderNums),
        priceRange: rangeSpread,
      };
    },
    [instrumentId, orderDefaultName, orderSettings, serviceId, logicGroupsList],
  );
};

export const useGetTechBuilderSimulationOrderRequestData = () => {
  const orderSettings = useSelector((state) => state.builder.orderSettingsList);
  const orderDefaultName = useBuilderLogicName();
  const logicGroupsList = useSelector((state) => state.builder.logicGroupsList);
  const indicatorIds = useMemo(() => [], []);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const termId = useSelector((state) => state.builder.selectedTermId);

  return useCallback(
    (name = '') => {
      const orderName = name
        ? `${name}_${BUILDER_ORDER_TYPES_NAME_BY_ID[BUILDER_ORDER_TYPES.TECH.ID]}`
        : orderDefaultName;
      const itemList = orderSettings.map(
        (
          {
            buySell,
            amount,
            entryPrice1Relative,
            stopLossRange,
            profitMargin,
            follow,
            counter,
            counterFixedValueRelative,
          },
          index,
        ) => {
          const number = index + 1;
          const orderNumber = number < 10 ? `0${number}` : number;
          const formatEntryPricePipsValue = (value) => {
            if (value !== null) {
              if (Number(value) >= 0) {
                return `startPrice+${value}`;
              }
              return `startPrice${value}`;
            }
            return null;
          };

          const entryPrice1Modified = formatEntryPricePipsValue(entryPrice1Relative);
          const counterPrice = formatEntryPricePipsValue(counterFixedValueRelative);

          return {
            id: number,
            name: `${orderName}_${orderNumber}`,
            side: buySell,
            quantity: amount,
            sl: stopLossRange,
            tp: profitMargin,
            entryPrice1: entryPrice1Modified,
            entryPrice2: null,
            follow,
            counter,
            counterPrice,
          };
        },
      );
      const strategyInfo = {
        name: orderName,
        instrumentId,
        itemList,
      };
      if (!logicGroupsList) return '';
      const { indicator1, indicator2, isAllSettlement, barType, buySell: buySellSignType } = logicGroupsList[0];
      if (indicator1) {
        indicatorIds.push(indicator1);
      }
      if (indicator2) {
        indicatorIds.push(indicator2);
      }

      return {
        strategyInfo,
        serviceId,
        termId,
        indicatorIds,
        isAutoSettle: isAllSettlement,
        buySellSignType,
        chartType: barType,
      };
    },
    [instrumentId, orderDefaultName, termId, orderSettings, indicatorIds, serviceId, logicGroupsList],
  );
};

export const useBuilderValidateAmount = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const { quantityPrecision, sellQuantityMin, sellQuantityMax, buyQuantityMin, buyQuantityMax } = useInstrumentSettings(
    instrumentId,
    TRADE_METHODS.AP.ID,
  );
  const orderType = useSelector((state) => state.builder.orderType);
  const isSingle = useMemo(() => orderType === BUILDER_ORDER_TYPES.SINGLE.ID, [orderType]);
  const selectedSellBuyId = useSelector(
    (state) => state.builder[isSingle ? 'singleOrder' : 'multiOrder'].selectedSellBuyId,
  );
  const quantityUnit = useMemo(() => getServiceQuantityUnit(serviceId), [serviceId]);

  const [amountMaxValue, step] = useMemo(() => {
    if (serviceId === FX) {
      const minFXValue = getAPQuantityStep(instrumentId);
      return [minFXValue, QUANTITY_MAX_VALUE, minFXValue];
    }

    return selectedSellBuyId === BUY_SELL_MAIN.BUY.ID
      ? [buyQuantityMin, buyQuantityMax, quantityPrecision]
      : [sellQuantityMin, sellQuantityMax, quantityPrecision];
  }, [
    serviceId,
    instrumentId,
    selectedSellBuyId,
    buyQuantityMin,
    buyQuantityMax,
    sellQuantityMin,
    sellQuantityMax,
    quantityPrecision,
  ]);
  const isBuy = useMemo(() => Number(selectedSellBuyId) === BUY_SELL_MAIN.BUY.ID, [selectedSellBuyId]);
  const quantityMin = isBuy ? buyQuantityMin : sellQuantityMin;
  return useCallback(
    (value) => {
      let amountError = { isValid: true, errorMessage: '' };
      if (value === '' || value == null) {
        amountError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        amountError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < quantityMin ||
        Number(value) > amountMaxValue ||
        (quantityPrecision >= 1 && Number(value) % quantityPrecision !== 0)
      ) {
        amountError = {
          isValid: false,
          errorMessage: `数量は${quantityMin}${quantityUnit}以上、${amountMaxValue}${quantityUnit}以下、${step}${quantityUnit}単位でご設定ください。`, // eslint-disable-line
        };
      }

      return amountError;
    },
    [amountMaxValue, quantityUnit, quantityPrecision, step, quantityMin],
  );
};

export const useBuilderValidateEntryPrice = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const basePrice = useBuilderBasePrice();
  const rangeSpread = useSelector((state) => state.builder.multiOrder.rangeSpread);
  const orderType = useSelector((state) => state.builder.orderType);
  const { pricePrecision, initialPrecision } = useBuilderPricePrecision();

  const entryPriceMinValue = useMemo(
    () => decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), pricePrecision),
    [basePrice, pricePrecision],
  );

  const entryPriceMaxValue = useMemo(() => {
    if (serviceId !== FX && orderType === BUILDER_ORDER_TYPES.MULTI.ID) {
      return decimalRounding(Decimal.add(basePrice, Number(rangeSpread)), pricePrecision);
    }
    return decimalRounding(
      Decimal.mul(basePrice, serviceId === FX ? BASE_PRICE_MAX_MULTIPLIER : BASE_PRICE_MAX_ETF_MULTIPLIER),
      pricePrecision,
    );
  }, [basePrice, pricePrecision, serviceId, rangeSpread, orderType]);

  const currencyUnit = useMemo(() => {
    if (serviceId !== ETF) {
      return '';
    }
    return getCurrencyUnit(instrumentId);
  }, [serviceId, instrumentId]);
  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pricePrecision : initialPrecision;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${currencyUnit}${unitText}`;
  }, [serviceId, pricePrecision, initialPrecision, currencyUnit]);

  return useCallback(
    (value) => {
      let entryPriceError = { isValid: true, errorMessage: '' };
      if (value === '' || value == null) {
        entryPriceError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        entryPriceError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < entryPriceMinValue ||
        Number(value) > entryPriceMaxValue ||
        (initialPrecision >= 1 && Number(value) % initialPrecision !== 0)
      ) {
        entryPriceError = {
          isValid: false,
          errorMessage: `価格は${entryPriceMinValue}${currencyUnit}以上、${entryPriceMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return entryPriceError;
    },
    [entryPriceMinValue, entryPriceMaxValue, initialPrecision, currencyUnit, stepValue],
  );
};

export const useBuilderValidateOCOPrice = () => {
  const { selectedSellBuyId: singleSide } = useSelector((state) => state.builder.singleOrder);
  const { selectedSellBuyId: multiSide } = useSelector((state) => state.builder.multiOrder);
  const orderType = useSelector((state) => state.builder.orderType);
  const entryPriceValue = useSelector((state) => state.builder.singleOrder.entryPriceValue);
  const currentSide = useMemo(
    () => (orderType === BUILDER_ORDER_TYPES.SINGLE.ID ? singleSide : multiSide),
    [orderType, singleSide, multiSide],
  );
  const isBuy = useMemo(() => Number(currentSide) === BUY_SELL_MAIN.BUY.ID, [currentSide]);

  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const basePrice = useBuilderBasePrice();
  const { pricePrecision, initialPrecision } = useBuilderPricePrecision();
  const entryPriceMinValue = useMemo(
    () => decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), pricePrecision),
    [basePrice, pricePrecision],
  );

  // TODO CFD FXかそうでないかの判定で問題ないか要確認(また、定数名をどうするか)
  const entryPriceMaxValue = useMemo(() => {
    const multiplier = serviceId === FX ? BASE_PRICE_MAX_MULTIPLIER : BASE_PRICE_MAX_ETF_MULTIPLIER;
    return decimalRounding(Decimal.mul(basePrice, multiplier), pricePrecision);
  }, [basePrice, pricePrecision, serviceId]);
  const currencyUnit = useMemo(() => {
    if (serviceId !== ETF) {
      return '';
    }
    return getCurrencyUnit(instrumentId);
  }, [serviceId, instrumentId]);
  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pricePrecision : initialPrecision;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${currencyUnit}${unitText}`;
  }, [serviceId, pricePrecision, initialPrecision, currencyUnit]);

  return useCallback(
    (value, entryPriceCurrentValue = null) => {
      const currentEntryPrice = entryPriceCurrentValue || entryPriceValue;
      if (value === '' || value == null) {
        return { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      }
      if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        return { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      }
      if (
        isBuy &&
        (Number(currentEntryPrice) >= Number(value) ||
          entryPriceMaxValue < Number(value) ||
          (initialPrecision >= 1 && Number(value) % initialPrecision !== 0))
      ) {
        return {
          isValid: false,
          errorMessage: `価格は${currentEntryPrice}${currencyUnit}より高い価格、${entryPriceMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }
      if (
        !isBuy &&
        (Number(value) >= Number(currentEntryPrice) ||
          Number(value) < entryPriceMinValue ||
          (initialPrecision >= 1 && Number(value) % initialPrecision !== 0))
      ) {
        return {
          isValid: false,
          errorMessage: `価格は${entryPriceMinValue}${currencyUnit}以上、${currentEntryPrice}${currencyUnit}より低い価格、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }
      return { isValid: true, errorMessage: '' };
    },
    [entryPriceValue, isBuy, currencyUnit, initialPrecision, entryPriceMaxValue, entryPriceMinValue, stepValue],
  );
};

export const useBuilderValidateEntryPipsPrice = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const basePrice = useBuilderBasePriceBySide();
  const rangeSpread = useSelector((state) => state.builder.multiOrder.rangeSpread);
  const orderType = useSelector((state) => state.builder.orderType);
  const { pipsPrecision, pricePrecision, initialPipsPrecision } = useBuilderPricePrecision();
  const pipsConversion = usePipConversion(instrumentId);

  const minPriceTemplate = useMemo(() => {
    const tempResult = decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), pricePrecision);
    return decimalRounding(Decimal.div(tempResult, pipsConversion), pipsPrecision);
  }, [basePrice, pricePrecision, pipsPrecision, pipsConversion]);

  const minPrice = useMemo(() => {
    if (serviceId !== FX && orderType === BUILDER_ORDER_TYPES.MULTI.ID) {
      return -Number(rangeSpread);
    }

    return Number(Decimal.sub(0, minPriceTemplate));
  }, [minPriceTemplate, rangeSpread, serviceId, orderType]);

  const maxPrice = useMemo(() => {
    if (serviceId !== FX && orderType === BUILDER_ORDER_TYPES.MULTI.ID) {
      return Number(rangeSpread);
    }

    if (serviceId === FX) {
      return minPriceTemplate;
    }
    const tempResult = decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MAX_ETF_MULTIPLIER), pricePrecision);
    return decimalRounding(Decimal.div(tempResult, pipsConversion), pipsPrecision);
  }, [serviceId, minPriceTemplate, pricePrecision, basePrice, pipsConversion, pipsPrecision, orderType, rangeSpread]);

  const currencyUnit = useMemo(() => {
    if (serviceId === FX) {
      return 'pips';
    }
    if (serviceId === CFD) {
      return '';
    }
    return getCurrencyUnit(instrumentId);
  }, [serviceId, instrumentId]);
  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pipsPrecision : initialPipsPrecision;
    const innerCurrencyUnit = serviceId === FX ? '' : currencyUnit;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${innerCurrencyUnit}${unitText}`;
  }, [serviceId, currencyUnit, pipsPrecision, initialPipsPrecision]);

  return useCallback(
    (value) => {
      let entryPricePipsError = { isValid: true, errorMessage: '' };
      if (value === '') {
        entryPricePipsError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        entryPricePipsError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < minPrice ||
        Number(value) > maxPrice ||
        (initialPipsPrecision >= 1 && Number(value) % initialPipsPrecision !== 0)
      ) {
        entryPricePipsError = {
          isValid: false,
          errorMessage: `前日終値は${minPrice}${currencyUnit}以上、+${maxPrice}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return entryPricePipsError;
    },
    [minPrice, maxPrice, initialPipsPrecision, currencyUnit, stepValue],
  );
};

export const useBuilderValidateProfitMargin = (outerRangeSpread = null, check = true) => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const rangeSpread = useSelector((state) => state.builder.multiOrder.rangeSpread);
  const { pipsPrecision, initialPipsPrecision } = useBuilderPricePrecision();
  const profitMarginMinValue = useMemo(() => {
    if (serviceId === FX) {
      return COMMON_PIPS_MIN_VALUE;
    }
    if (serviceId === CFD) {
      return CFD_MIN_VALUE;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return ETF_JPY_PRICE_MIN;
    }
    return ETF_NOT_JPY_PRICE_MIN;
  }, [serviceId, instrumentId]);

  const profitMarginMaxValue = useMemo(() => {
    if (check) {
      return Number(rangeSpread);
    }
    return Number(outerRangeSpread);
  }, [rangeSpread, outerRangeSpread, check]);

  const currencyUnit = useMemo(() => getPipsLabel(serviceId, instrumentId), [serviceId, instrumentId]);
  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pipsPrecision : initialPipsPrecision;
    const innerCurrencyUnit = serviceId === FX ? '' : currencyUnit;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${innerCurrencyUnit}${unitText}`;
  }, [serviceId, currencyUnit, pipsPrecision, initialPipsPrecision]);

  return useCallback(
    (value) => {
      let profitMarginError = { isValid: true, errorMessage: '' };
      if (value === '' || value == null) {
        profitMarginError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        profitMarginError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < profitMarginMinValue ||
        Number(value) > profitMarginMaxValue ||
        (initialPipsPrecision >= 1 && Number(value) % initialPipsPrecision !== 0)
      ) {
        profitMarginError = {
          isValid: false,
          errorMessage: `利確幅は+${profitMarginMinValue}${currencyUnit}以上、+${profitMarginMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return profitMarginError;
    },
    [profitMarginMinValue, profitMarginMaxValue, initialPipsPrecision, currencyUnit, stepValue],
  );
};

export const useTechConfigValidateProfitMargin = () => {
  const rangeSpread = useSelector((state) => state.tech.config[BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.RANGE_SPREAD]);
  const check = useSelector(
    (state) => state.tech.config[BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.RANGE_SPREAD_IS_CHECKED],
  );

  return useBuilderValidateProfitMargin(rangeSpread, check);
};

export const useBuilderValidateStopLoss = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const basePrice = useBuilderBasePrice();
  const { pipsPrecision, pricePrecision, initialPipsPrecision } = useBuilderPricePrecision();
  const pipsConversion = usePipConversion(instrumentId);
  const { profitMarginPips: defaultProfitMargin } = useBuilderProfitMargin();
  const multipliedBasePrice = useMemo(
    () => decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), pricePrecision),
    [basePrice, pricePrecision],
  );
  const stopLossMinValue = useMemo(
    () => decimalRounding(Decimal.sub(0, multipliedBasePrice).div(pipsConversion), pipsPrecision),
    [multipliedBasePrice, pipsConversion, pipsPrecision],
  );

  const stopLossMaxValue = useMemo(() => {
    if (serviceId === FX) {
      return STOP_LOSS_MAX_VALUE;
    }
    return Number(Decimal.mul(defaultProfitMargin, STOP_LOSS_ETF_CFD_MAX_MULTIPLIER).neg());
  }, [serviceId, defaultProfitMargin]);

  const currencyUnit = useMemo(() => getPipsLabel(serviceId, instrumentId), [serviceId, instrumentId]);

  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pipsPrecision : initialPipsPrecision;
    const innerCurrencyUnit = serviceId === FX ? '' : currencyUnit;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${innerCurrencyUnit}${unitText}`;
  }, [serviceId, currencyUnit, pipsPrecision, initialPipsPrecision]);

  return useCallback(
    (value) => {
      let stopLossError = { isValid: true, errorMessage: '' };
      if (value === '' || value == null) {
        stopLossError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        stopLossError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < stopLossMinValue ||
        Number(value) > stopLossMaxValue ||
        (initialPipsPrecision >= 1 && Number(value) % initialPipsPrecision !== 0)
      ) {
        stopLossError = {
          isValid: false,
          errorMessage: `損切幅は${stopLossMinValue}${currencyUnit}以上、${stopLossMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return stopLossError;
    },
    [stopLossMinValue, initialPipsPrecision, currencyUnit, stopLossMaxValue, stepValue],
  );
};

export const useBuilderValidateFollow = () => {
  const { selectedSellBuyId: singleSide } = useSelector((state) => state.builder.singleOrder);
  const { selectedSellBuyId: multiSide } = useSelector((state) => state.builder.multiOrder);
  const orderType = useSelector((state) => state.builder.orderType);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const basePrice = useBuilderBasePrice();
  const defaultItemsCount = useBuilderItemsCount();
  const { profitMarginPips: defaultProfitMargin } = useBuilderProfitMargin();
  const { pipsPrecision, initialPipsPrecision } = useBuilderPricePrecision();
  const pipsConversion = usePipConversion(instrumentId);
  const currentSide = useMemo(
    () => (orderType === BUILDER_ORDER_TYPES.SINGLE.ID ? singleSide : multiSide),
    [orderType, singleSide, multiSide],
  );
  const isBuy = useMemo(() => Number(currentSide) === BUY_SELL_MAIN.BUY.ID, [currentSide]);
  const followMinValue = useMemo(() => {
    if (isBuy) {
      if (serviceId === FX) {
        return COMMON_PIPS_MIN_VALUE;
      }
      if (serviceId === CFD) {
        return CFD_MIN_VALUE;
      }
      if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
        return ETF_JPY_PRICE_MIN;
      }
      return ETF_NOT_JPY_PRICE_MIN;
    }

    if (serviceId !== FX) {
      return -Number(Decimal.mul(defaultProfitMargin, defaultItemsCount - 1));
    }

    return decimalRounding(Decimal.sub(0, basePrice).div(pipsConversion), pipsPrecision);
  }, [
    isBuy,
    basePrice,
    pipsConversion,
    pipsPrecision,
    serviceId,
    instrumentId,
    defaultItemsCount,
    defaultProfitMargin,
  ]);

  const followMaxValue = useMemo(() => {
    if (isBuy) {
      if (serviceId !== FX) {
        return Number(Decimal.mul(defaultProfitMargin, defaultItemsCount - 1));
      }

      return decimalRounding(Decimal.div(basePrice, pipsConversion), pipsPrecision);
    }
    if (serviceId === FX) {
      return COMMON_PIPS_MAX_VALUE;
    }
    if (serviceId === CFD) {
      return -CFD_MIN_VALUE;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return -ETF_JPY_PRICE_MIN;
    }
    return -ETF_NOT_JPY_PRICE_MIN;
  }, [
    isBuy,
    basePrice,
    pipsConversion,
    pipsPrecision,
    serviceId,
    defaultItemsCount,
    defaultProfitMargin,
    instrumentId,
  ]);

  const currencyUnit = useMemo(() => {
    if (serviceId === FX) {
      return 'pips';
    }
    if (serviceId === CFD) {
      return '';
    }
    return getCurrencyUnit(instrumentId);
  }, [serviceId, instrumentId]);
  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pipsPrecision : initialPipsPrecision;
    const innerCurrencyUnit = serviceId === FX ? '' : currencyUnit;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${innerCurrencyUnit}${unitText}`;
  }, [serviceId, currencyUnit, pipsPrecision, initialPipsPrecision]);

  return useCallback(
    (value) => {
      let followError = { isValid: true, errorMessage: '' };
      if (value === '' || value == null) {
        followError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        followError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < followMinValue ||
        Number(value) > followMaxValue ||
        (initialPipsPrecision >= 1 && Number(value) % initialPipsPrecision !== 0)
      ) {
        followError = {
          isValid: false,
          errorMessage: isBuy
            ? `フォロー値は+${followMinValue}${currencyUnit}以上、+${followMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。` // eslint-disable-line
            : `フォロー値は${followMinValue}${currencyUnit}以上、${followMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return followError;
    },
    [followMinValue, followMaxValue, isBuy, initialPipsPrecision, currencyUnit, stepValue],
  );
};

export const useBuilderValidateCounter = () => {
  const { selectedSellBuyId: singleSide } = useSelector((state) => state.builder.singleOrder);
  const { selectedSellBuyId: multiSide } = useSelector((state) => state.builder.multiOrder);
  const orderType = useSelector((state) => state.builder.orderType);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const basePrice = useBuilderBasePrice();
  const { pipsPrecision, pricePrecision, initialPipsPrecision } = useBuilderPricePrecision();
  const pipsConversion = usePipConversion(instrumentId);
  const multipliedBasePrice = useMemo(
    () => decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), pricePrecision),
    [basePrice, pricePrecision],
  );
  const currentSide = useMemo(
    () => (orderType === BUILDER_ORDER_TYPES.SINGLE.ID ? singleSide : multiSide),
    [orderType, singleSide, multiSide],
  );
  const isBuy = useMemo(() => Number(currentSide) === BUY_SELL_MAIN.BUY.ID, [currentSide]);

  const counterMinValue = useMemo(() => {
    if (isBuy) {
      return decimalRounding(Decimal.sub(0, multipliedBasePrice).div(pipsConversion), pipsPrecision);
    }
    if (serviceId === FX) {
      return COMMON_PIPS_MIN_VALUE;
    }
    if (serviceId === CFD) {
      return CFD_MIN_VALUE;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return ETF_JPY_PRICE_MIN;
    }
    return ETF_NOT_JPY_PRICE_MIN;
  }, [isBuy, multipliedBasePrice, pipsConversion, pipsPrecision, serviceId, instrumentId]);

  const counterMaxValue = useMemo(() => {
    if (isBuy) {
      if (serviceId === FX) {
        return COMMON_PIPS_MAX_VALUE;
      }
      if (serviceId === CFD) {
        return -CFD_MIN_VALUE;
      }
      if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
        return -ETF_JPY_PRICE_MIN;
      }
      return -ETF_NOT_JPY_PRICE_MIN;
    }
    return decimalRounding(Decimal.div(multipliedBasePrice, pipsConversion), pipsPrecision);
  }, [isBuy, multipliedBasePrice, pipsConversion, pipsPrecision, serviceId, instrumentId]);

  const currencyUnit = useMemo(() => {
    if (serviceId === FX) {
      return 'pips';
    }
    if (serviceId === CFD) {
      return '';
    }
    return getCurrencyUnit(instrumentId);
  }, [serviceId, instrumentId]);
  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pipsPrecision : initialPipsPrecision;
    const innerCurrencyUnit = serviceId === FX ? '' : currencyUnit;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${innerCurrencyUnit}${unitText}`;
  }, [serviceId, currencyUnit, pipsPrecision, initialPipsPrecision]);

  return useCallback(
    (value) => {
      let counterError = { isValid: true, errorMessage: '' };
      if (value === '' || value == null) {
        counterError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        counterError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < counterMinValue ||
        Number(value) > counterMaxValue ||
        (initialPipsPrecision >= 1 && Number(value) % initialPipsPrecision !== 0)
      ) {
        counterError = {
          isValid: false,
          errorMessage: isBuy
            ? `カウンター${
                orderType === BUILDER_ORDER_TYPES.SINGLE.ID ? '幅' : '値'
              }は${counterMinValue}${currencyUnit}以上、${counterMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。` // eslint-disable-line
            : `カウンター${
                orderType === BUILDER_ORDER_TYPES.SINGLE.ID ? '幅' : '値'
              }は+${counterMinValue}${currencyUnit}以上、+${counterMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return counterError;
    },
    [counterMinValue, counterMaxValue, isBuy, orderType, initialPipsPrecision, currencyUnit, stepValue],
  );
};

export const useBuilderValidateCounterPrice = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const basePrice = useBuilderBasePrice();
  const { pricePrecision, initialPrecision } = useBuilderPricePrecision();
  const counterPriceMinValue = useMemo(
    () => decimalRounding(Decimal.mul(basePrice, BASE_PRICE_MIN_MULTIPLIER), pricePrecision),
    [basePrice, pricePrecision],
  );

  // TODO CFD FXかそうでないかの判定で問題ないか要確認(また、定数名をどうするか)
  const counterPriceMaxValue = useMemo(() => {
    const multiplier = serviceId === FX ? BASE_PRICE_MAX_MULTIPLIER : BASE_PRICE_MAX_ETF_MULTIPLIER;
    return decimalRounding(Decimal.mul(basePrice, multiplier), pricePrecision);
  }, [basePrice, pricePrecision, serviceId]);

  const currencyUnit = useMemo(() => {
    if (serviceId !== ETF) {
      return '';
    }
    return getCurrencyUnit(instrumentId);
  }, [serviceId, instrumentId]);
  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pricePrecision : initialPrecision;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${currencyUnit}${unitText}`;
  }, [serviceId, pricePrecision, initialPrecision, currencyUnit]);

  return useCallback(
    (value) => {
      let counterPriceError = { isValid: true, errorMessage: '' };
      if (value === '' || value == null) {
        counterPriceError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        counterPriceError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < counterPriceMinValue ||
        Number(value) > counterPriceMaxValue ||
        (initialPrecision >= 1 && Number(value) % initialPrecision !== 0)
      ) {
        counterPriceError = {
          isValid: false,
          // eslint-disable-next-line max-len
          errorMessage: `カウンター価格は${counterPriceMinValue}${currencyUnit}以上、${counterPriceMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return counterPriceError;
    },
    [counterPriceMinValue, counterPriceMaxValue, initialPrecision, currencyUnit, stepValue],
  );
};

export const useBuilderValidateRangeSpread = () => {
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const serviceId = useGetBuilderServiceId();
  const pipsConversion = usePipConversion(instrumentId);
  const fluctuationRange = useBuilderFluctuationRange();
  const { pipsPrecision, initialPipsPrecision } = useBuilderPricePrecision();

  const rangeSpreadMinValue = useMemo(() => {
    if (serviceId !== ETF) {
      return RANGE_SPREAD_MIN_VALUE;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return RANGE_ETF_JPY_SPREAD_STEP;
    }
    return RANGE_ETF_NOT_JPY_SPREAD_STEP;
  }, [serviceId, instrumentId]);

  const rangeSpreadMaxValue = useMemo(
    () => decimalRounding(Decimal.mul(fluctuationRange, 3).div(pipsConversion), pipsPrecision),
    [fluctuationRange, pipsConversion, pipsPrecision],
  );

  const currencyUnit = useMemo(() => getPipsLabel(serviceId, instrumentId), [serviceId, instrumentId]);

  const stepValue = useMemo(() => {
    const prefixText = serviceId === FX ? '小数点' : '';
    const precision = serviceId === FX ? pipsPrecision : initialPipsPrecision;
    const innerCurrencyUnit = serviceId === FX ? '' : currencyUnit;
    const unitText = serviceId === FX ? '位' : '単位';
    return `${prefixText}${precision}${innerCurrencyUnit}${unitText}`;
  }, [serviceId, currencyUnit, pipsPrecision, initialPipsPrecision]);

  return useCallback(
    (value) => {
      let rangeSpreadError = { isValid: true, errorMessage: '' };
      if (value === '' || value == null) {
        rangeSpreadError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
      } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
        rangeSpreadError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
      } else if (
        Number(value) < rangeSpreadMinValue ||
        Number(value) > rangeSpreadMaxValue ||
        (initialPipsPrecision >= 1 && Number(value) % initialPipsPrecision !== 0)
      ) {
        rangeSpreadError = {
          isValid: false,
          errorMessage: `レンジ幅は${rangeSpreadMinValue}${currencyUnit}以上、${rangeSpreadMaxValue}${currencyUnit}以下、${stepValue}まででご設定ください。`, // eslint-disable-line
        };
      }

      return rangeSpreadError;
    },
    [rangeSpreadMinValue, rangeSpreadMaxValue, initialPipsPrecision, currencyUnit, stepValue],
  );
};

export const useBuilderValidateItemsCount = () =>
  useCallback((value) => {
    let itemsCountError = { isValid: true, errorMessage: '' };
    if (value === '' || value == null) {
      itemsCountError = { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
    } else if (!String(value).match(NUMBER_VALIDATION_REG_ALLOW_NEGATIVE)) {
      itemsCountError = { isValid: false, errorMessage: VALIDATION_ERROR_INVALID_VALUE };
    } else if (value < ITEMS_COUNT_MIN_VALUE || value > ITEMS_COUNT_MAX_VALUE) {
      itemsCountError = {
        isValid: false,
        errorMessage: '本数は1本以上100本以下でご設定ください。',
      };
    }

    return itemsCountError;
  }, []);

export const useBuilderSingleDisabledValidationOptions = () => {
  const entryPriceTypeId = useSelector((state) => state.builder.singleOrder.entryPriceTypeId);
  const ocoIsChecked = useSelector((state) => state.builder.singleOrder.ocoIsChecked);
  const stopLossSpreadIsChecked = useSelector((state) => state.builder.singleOrder.stopLossSpreadIsChecked);
  const followValueIsChecked = useSelector((state) => state.builder.singleOrder.followValueIsChecked);
  const counterValueIsChecked = useSelector((state) => state.builder.singleOrder.counterValueIsChecked);
  const counterValueTypeId = useSelector((state) => state.builder.singleOrder.counterValueTypeId);

  return useMemo(() => {
    const disabledOptions = [];
    if (entryPriceTypeId === ENTRY_PRICE_VALUES.DESIGNATION.ID) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.ENTRY_PRICE_PIPS_VALUE);
    } else {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.ENTRY_PRICE_VALUE);
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.OCO_VALUE);
    }
    if (!ocoIsChecked) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.OCO_VALUE);
    }
    if (!stopLossSpreadIsChecked) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.STOP_LOSS_SPREAD);
    }
    if (!followValueIsChecked) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.FOLLOW_VALUE);
    }
    if (!counterValueIsChecked) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_VALUE);
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_PRICE_VALUE);
    } else if (counterValueTypeId === OPTIONS_COUNTER_TYPE[1].id) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_VALUE);
    } else {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_PRICE_VALUE);
    }

    return disabledOptions;
  }, [
    entryPriceTypeId,
    ocoIsChecked,
    stopLossSpreadIsChecked,
    followValueIsChecked,
    counterValueIsChecked,
    counterValueTypeId,
  ]);
};

export const useBuilderMultiDisabledValidationOptions = () => {
  const entryPriceTypeId = useSelector((state) => state.builder.multiOrder.entryPriceTypeId);
  const stopLossSpreadIsChecked = useSelector((state) => state.builder.multiOrder.stopLossSpreadIsChecked);
  const followValueIsChecked = useSelector((state) => state.builder.multiOrder.followValueIsChecked);
  const counterValueIsChecked = useSelector((state) => state.builder.multiOrder.counterValueIsChecked);

  return useMemo(() => {
    const disabledOptions = [];
    if (entryPriceTypeId === ENTRY_PRICE_VALUES.DESIGNATION.ID) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_PIPS_VALUE);
    } else {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_VALUE);
    }
    if (!stopLossSpreadIsChecked) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD);
    }
    if (!followValueIsChecked) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE);
    }
    if (!counterValueIsChecked) {
      disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE);
    }

    return disabledOptions;
  }, [entryPriceTypeId, stopLossSpreadIsChecked, followValueIsChecked, counterValueIsChecked]);
};

export const useTechBuilderMultiDisabledValidateOptions = (isConfig = false) => {
  const useForConfig = () => {
    const {
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.RANGE_SPREAD_IS_CHECKED]: rangeSpreadIsChecked,
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.ITEMS_COUNT_IS_CHECKED]: itemsCountIsChecked,
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.AMOUNT_IS_CHECKED]: amountIsChecked,
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.PROFIT_MARGIN_IS_CHECKED]: profitMarginIsChecked,
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.STOP_LOSS_SPREAD_IS_CHECKED]: stopLossSpreadIsChecked,
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.FOLLOW_VALUE_IS_CHECKED]: followIsChecked,
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.COUNTER_VALUE_IS_CHECKED]: counterValueIsChecked,
    } = useSelector((state) => state.tech.config);

    return useMemo(() => {
      const disabledOptions = [];
      if (!rangeSpreadIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.RANGE_SPREAD);
      }
      if (!itemsCountIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ITEMS_COUNT);
      }
      if (!amountIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.AMOUNT);
      }
      if (!profitMarginIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.PROFIT_MARGIN);
      }
      if (!stopLossSpreadIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD);
      }
      if (!followIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE);
      }
      if (!counterValueIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE);
      }

      return disabledOptions;
    }, [
      rangeSpreadIsChecked,
      itemsCountIsChecked,
      amountIsChecked,
      profitMarginIsChecked,
      stopLossSpreadIsChecked,
      followIsChecked,
      counterValueIsChecked,
    ]);
  };

  const useForBuilder = () => {
    const stopLossSpreadIsChecked = useSelector((state) => state.builder.multiOrder.stopLossSpreadIsChecked);
    const followValueIsChecked = useSelector((state) => state.builder.multiOrder.followValueIsChecked);
    const counterValueIsChecked = useSelector((state) => state.builder.multiOrder.counterValueIsChecked);

    return useMemo(() => {
      const disabledOptions = [];
      if (!stopLossSpreadIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD);
      }
      if (!followValueIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE);
      }
      if (!counterValueIsChecked) {
        disabledOptions.push(BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE);
      }

      return disabledOptions;
    }, [stopLossSpreadIsChecked, followValueIsChecked, counterValueIsChecked]);
  };

  const forConfig = useForConfig();
  const forBuilder = useForBuilder();

  return useMemo(() => (isConfig ? forConfig : forBuilder), [isConfig, forConfig, forBuilder]);
};

export const useBuilderValidateSingleOptions = () => {
  const dispatch = useDispatch();
  const amount = useSelector((state) => state.builder.singleOrder.amount);
  const entryPriceValue = useSelector((state) => state.builder.singleOrder.entryPriceValue);
  const entryPricePipsValue = useSelector((state) => state.builder.singleOrder.entryPricePipsValue);
  const ocoValue = useSelector((state) => state.builder.singleOrder.ocoValue);
  const profitMargin = useSelector((state) => state.builder.singleOrder.profitMargin);
  const stopLossSpread = useSelector((state) => state.builder.singleOrder.stopLossSpread);
  const followValue = useSelector((state) => state.builder.singleOrder.followValue);
  const counterValue = useSelector((state) => state.builder.singleOrder.counterValue);
  const counterPriceValue = useSelector((state) => state.builder.singleOrder.counterPriceValue);
  const validateAmount = useBuilderValidateAmount();
  const validateEntryPrice = useBuilderValidateEntryPrice();
  const validateOCOPrice = useBuilderValidateOCOPrice();
  const validateEntryPipsPrice = useBuilderValidateEntryPipsPrice();
  const validateProfitMargin = useBuilderValidateProfitMargin();
  const validateStopLoss = useBuilderValidateStopLoss();
  const validateFollow = useBuilderValidateFollow();
  const validateCounter = useBuilderValidateCounter();
  const validateCounterPrice = useBuilderValidateCounterPrice();
  const disabledValidationOptions = useBuilderSingleDisabledValidationOptions();

  const validationValues = useMemo(
    () => ({
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.AMOUNT]: { value: amount, validate: validateAmount },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.ENTRY_PRICE_VALUE]: {
        value: entryPriceValue,
        validate: validateEntryPrice,
      },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.ENTRY_PRICE_PIPS_VALUE]: {
        value: entryPricePipsValue,
        validate: validateEntryPipsPrice,
      },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.OCO_VALUE]: {
        value: ocoValue,
        validate: validateOCOPrice,
      },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.PROFIT_MARGIN]: {
        value: profitMargin,
        validate: validateProfitMargin,
      },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.FOLLOW_VALUE]: { value: followValue, validate: validateFollow },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_VALUE]: { value: counterValue, validate: validateCounter },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_PRICE_VALUE]: {
        value: counterPriceValue,
        validate: validateCounterPrice,
      },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.STOP_LOSS_SPREAD]: {
        value: stopLossSpread,
        validate: validateStopLoss,
      },
    }),
    [
      amount,
      validateAmount,
      entryPriceValue,
      validateEntryPrice,
      validateOCOPrice,
      ocoValue,
      entryPricePipsValue,
      validateEntryPipsPrice,
      profitMargin,
      validateProfitMargin,
      followValue,
      validateFollow,
      counterValue,
      validateCounter,
      counterPriceValue,
      validateCounterPrice,
      stopLossSpread,
      validateStopLoss,
    ],
  );

  return useCallback(() => {
    const validationArray = BUILDER_ORDER_CONFIGURATION_VALIDATED_OPTIONS.SINGLE.reduce((arr, fieldName) => {
      if (!disabledValidationOptions.includes(fieldName)) {
        const { value, validate } = validationValues[fieldName];
        const { isValid, errorMessage } = validate(value);
        arr.push({ fieldName, errorMessage, hasValidationError: !isValid });
      }
      return arr;
    }, []);
    const hasErrors = validationArray.some(({ hasValidationError }) => hasValidationError);
    validationArray.forEach(({ fieldName, errorMessage, hasValidationError }) => {
      dispatch(changeBuilderSingleOrderValidationErrors({ fieldName, errorMessage, hasValidationError }));
    });

    return { hasErrors };
  }, [disabledValidationOptions, validationValues, dispatch]);
};

export const useBuilderValidateMultiOptions = () => {
  const dispatch = useDispatch();
  const amount = useSelector((state) => state.builder.multiOrder.amount);
  const entryPriceValue = useSelector((state) => state.builder.multiOrder.entryPriceValue);
  const entryPricePipsValue = useSelector((state) => state.builder.multiOrder.entryPricePipsValue);
  const profitMargin = useSelector((state) => state.builder.multiOrder.profitMargin);
  const followValue = useSelector((state) => state.builder.multiOrder.followValue);
  const counterValue = useSelector((state) => state.builder.multiOrder.counterValue);
  const rangeSpread = useSelector((state) => state.builder.multiOrder.rangeSpread);
  const itemsCount = useSelector((state) => state.builder.multiOrder.itemsCount);
  const stopLossSpread = useSelector((state) => state.builder.multiOrder.stopLossSpread);
  const validateRangeSpread = useBuilderValidateRangeSpread();
  const validateItemsCount = useBuilderValidateItemsCount();
  const validateAmount = useBuilderValidateAmount();
  const validateEntryPrice = useBuilderValidateEntryPrice();
  const validateEntryPipsPrice = useBuilderValidateEntryPipsPrice();
  const validateProfitMargin = useBuilderValidateProfitMargin();
  const validateStopLoss = useBuilderValidateStopLoss();
  const validateFollow = useBuilderValidateFollow();
  const validateCounter = useBuilderValidateCounter();
  const disabledValidationOptions = useBuilderMultiDisabledValidationOptions();

  const validationValues = useMemo(
    () => ({
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.AMOUNT]: { value: amount, validate: validateAmount },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_VALUE]: {
        value: entryPriceValue,
        validate: validateEntryPrice,
      },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_PIPS_VALUE]: {
        value: entryPricePipsValue,
        validate: validateEntryPipsPrice,
      },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.PROFIT_MARGIN]: {
        value: profitMargin,
        validate: validateProfitMargin,
      },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE]: { value: followValue, validate: validateFollow },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE]: { value: counterValue, validate: validateCounter },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.RANGE_SPREAD]: { value: rangeSpread, validate: validateRangeSpread },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ITEMS_COUNT]: { value: itemsCount, validate: validateItemsCount },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD]: {
        value: stopLossSpread,
        validate: validateStopLoss,
      },
    }),
    [
      amount,
      validateAmount,
      entryPriceValue,
      validateEntryPrice,
      entryPricePipsValue,
      validateEntryPipsPrice,
      profitMargin,
      validateProfitMargin,
      followValue,
      validateFollow,
      counterValue,
      validateCounter,
      rangeSpread,
      validateRangeSpread,
      itemsCount,
      validateItemsCount,
      stopLossSpread,
      validateStopLoss,
    ],
  );

  return useCallback(() => {
    const validationArray = BUILDER_ORDER_CONFIGURATION_VALIDATED_OPTIONS.MULTI.reduce((arr, fieldName) => {
      if (!disabledValidationOptions.includes(fieldName)) {
        const { value, validate } = validationValues[fieldName];
        const { isValid, errorMessage } = validate(value);
        arr.push({ fieldName, errorMessage, hasValidationError: !isValid });
      }
      return arr;
    }, []);
    const hasErrors = validationArray.some(({ hasValidationError }) => hasValidationError);
    validationArray.forEach(({ fieldName, errorMessage, hasValidationError }) => {
      dispatch(changeBuilderMultiOrderValidationErrors({ fieldName, errorMessage, hasValidationError }));
    });

    return { hasErrors };
  }, [disabledValidationOptions, validationValues, dispatch]);
};

export const useTechBuilderValidateMultiOptions = (isConfig = false) => {
  const dispatch = useDispatch();
  const amount = useSelector((state) => state.builder.multiOrder.amount);
  const profitMargin = useSelector((state) => state.builder.multiOrder.profitMargin);
  const followValue = useSelector((state) => state.builder.multiOrder.followValue);
  const counterValue = useSelector((state) => state.builder.multiOrder.counterValue);
  const rangeSpread = useSelector((state) => state.builder.multiOrder.rangeSpread);
  const itemsCount = useSelector((state) => state.builder.multiOrder.itemsCount);
  const stopLossSpread = useSelector((state) => state.builder.multiOrder.stopLossSpread);
  const validateRangeSpread = useBuilderValidateRangeSpread();
  const validateItemsCount = useBuilderValidateItemsCount();
  const validateAmount = useBuilderValidateAmount();
  const validateProfitMargin = useBuilderValidateProfitMargin();
  const validateStopLoss = useBuilderValidateStopLoss();
  const validateFollow = useBuilderValidateFollow();
  const validateCounter = useBuilderValidateCounter();

  const techConfigValidateProfitMargin = useTechConfigValidateProfitMargin();

  const disabledValidationOptions = useTechBuilderMultiDisabledValidateOptions(isConfig);

  const validationValues = useMemo(
    () => ({
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.AMOUNT]: { value: amount, validate: validateAmount },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.PROFIT_MARGIN]: {
        value: profitMargin,
        validate: isConfig ? techConfigValidateProfitMargin : validateProfitMargin,
      },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.FOLLOW_VALUE]: { value: followValue, validate: validateFollow },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.COUNTER_VALUE]: { value: counterValue, validate: validateCounter },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.RANGE_SPREAD]: { value: rangeSpread, validate: validateRangeSpread },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.ITEMS_COUNT]: { value: itemsCount, validate: validateItemsCount },
      [BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.STOP_LOSS_SPREAD]: {
        value: stopLossSpread,
        validate: validateStopLoss,
      },
    }),
    [
      amount,
      validateAmount,
      profitMargin,
      validateProfitMargin,
      techConfigValidateProfitMargin,
      followValue,
      validateFollow,
      counterValue,
      validateCounter,
      rangeSpread,
      validateRangeSpread,
      itemsCount,
      validateItemsCount,
      stopLossSpread,
      validateStopLoss,
      isConfig,
    ],
  );

  return useCallback(() => {
    const validationArray = BUILDER_ORDER_CONFIGURATION_VALIDATED_OPTIONS.TECH.reduce((arr, fieldName) => {
      if (disabledValidationOptions.includes(fieldName)) {
        arr.push({ fieldName, errorMessage: '', hasValidationError: false });
      } else {
        const { value, validate } = validationValues[fieldName];
        const { isValid, errorMessage } = validate(value);
        arr.push({ fieldName, errorMessage, hasValidationError: !isValid });
      }
      return arr;
    }, []);
    const hasErrors = validationArray.some(({ hasValidationError }) => hasValidationError);
    validationArray.forEach(({ fieldName, errorMessage, hasValidationError }) => {
      dispatch(changeBuilderMultiOrderValidationErrors({ fieldName, errorMessage, hasValidationError }));
    });

    return { hasErrors };
  }, [disabledValidationOptions, validationValues, dispatch]);
};

const DEFAULT_SETS = 1;
export const useBuilderMaxStrategySetsValue = () => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);

  const transformedData = useMemo(
    () =>
      orderSettingsList.map((el) => ({
        side: el.buySell,
        quantity: Number(el.amount),
      })),
    [orderSettingsList],
  );

  const strategyList = useMemo(
    () => [
      {
        strategySets: DEFAULT_SETS,
        strategyDetail: {
          instrumentId,
          itemList: transformedData,
        },
      },
    ],
    [instrumentId, transformedData],
  );

  return useMemo(() => calculateMaxItemForCart({ strategyList, instrumentList }), [instrumentList, strategyList]);
};

export const useBuilderMarginRequirement = (rates, isTech) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);

  const convertEntryPrice1Value = useCallback(
    (price, buySell) => {
      if (!price) return price;

      if (!price.includes?.(TECH_START_PRICE_LITERAL)) {
        return price;
      }

      const formatPrice = Number(price.replace(TECH_START_PRICE_LITERAL, '')?.replaceAll(' ', ''));
      const rate = buySell === BUY_SELL_MAIN.BUY.ID ? rates?.askClose : rates?.bidClose;
      return formatPrice + rate;
    },
    [rates],
  );

  const strategyList = useMemo(() => {
    // TODO amount の変換に infinity や NaN、nullish の考慮は必要？
    const itemList = (orderSettingsList ?? []).map(({ amount, buySell, entryPrice1, entryPrice2 }) => ({
      quantity: Number(amount),
      side: String(buySell),
      entryPrice1: isTech ? convertEntryPrice1Value(entryPrice1, buySell) : entryPrice1,
      entryPrice2,
    }));
    return [
      {
        serviceId,
        instrumentId,
        itemList,
        strategySets: 1,
      },
    ];
  }, [serviceId, instrumentId, orderSettingsList, convertEntryPrice1Value, isTech]);
  return useCalculatingMarginByStrategyList(strategyList, 1);
};

export const STRATEGY_SETS_INPUT_NAME = 'strategySets';
export const STRATEGY_NAME_INPUT_NAME = 'strategyName';
export const NAME_VALIDATION_ERROR = '自動売買グループ名は40文字以内でご入力ください。';

export const useBuilderAddToCartLogic = ({ isModalOpen: isOpen, closeModal, isTechBuilder = false }) => {
  const [errorMessages, changeErrorMessages] = useState([]);

  const dispatch = useDispatch();

  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const termId = useSelector((state) => state.builder.selectedTermId);
  const activeCurrency = useSelector((state) => state.builder.activeCurrency);
  const rate = useSelector((state) => state.currencies.rates[activeCurrency]);
  const marginRequired = useBuilderMarginRequirement(rate, isTechBuilder);

  const simulationStatsKeyName = isTechBuilder ? 'simulationStat' : 'simulationStats';
  const marginRecommended = useSelector(
    (state) =>
      state.builder.simulationData[termId ?? defaultSelectionTermId]?.[simulationStatsKeyName]?.marginRecommended ?? 0,
  );
  const addToCartIsLoading = useSelector((state) => state.builder.addToCartIsLoading);

  const orderName = useBuilderLogicName(isTechBuilder);
  const getBuilderOrderRequestData = useGetBuilderOrderRequestData();
  const getTechBuilderOrderRequestData = useGetTechBuilderOrderRequestData();

  const orderNameRef = useRef({});
  useEffect(() => {
    if (orderNameRef?.current) orderNameRef.current = { orderName };
  }, [orderName]);

  useEffect(() => {
    if (isOpen) changeErrorMessages([]);
  }, [isOpen]);

  const [currentOrderName, setOrderName] = useState(orderName);
  const [currentSets, setSets] = useState(DEFAULT_ORDER_SETS_VALUE);

  const addToCartCallback = useCallback(() => {
    closeModal();
    dispatch(sendNotificationSuccess({ message: 'カートに追加されました。' }));
    dispatch(resetBuilderOrdersTables());
    dispatch(getBuilderChartDataRequest());
  }, [dispatch, closeModal]);

  const addToCart = useCallback(() => {
    if (errorMessages.length) return;

    const orderRequestData = getBuilderOrderRequestData(currentOrderName);
    dispatch(addToCartBuilderRequest({ strategySets: currentSets, orderRequestData, callback: addToCartCallback }));
  }, [errorMessages.length, getBuilderOrderRequestData, currentOrderName, dispatch, currentSets, addToCartCallback]);

  const addTechToCart = useCallback(() => {
    if (errorMessages.length) return;

    const orderRequestData = getTechBuilderOrderRequestData(currentOrderName);
    dispatch(addToCartTechBuilderRequest({ strategySets: currentSets, orderRequestData, callback: addToCartCallback }));
  }, [
    errorMessages.length,
    getTechBuilderOrderRequestData,
    currentOrderName,
    dispatch,
    currentSets,
    addToCartCallback,
  ]);
  const changeOrderName = useCallback((value) => {
    let newOrderName = String(value);
    if (newOrderName.length > ORDER_NAME_MAX_LENGTH) newOrderName = newOrderName.slice(0, ORDER_NAME_MAX_LENGTH);

    setOrderName(newOrderName);
  }, []);

  const roundedMarginRecommended = useMemo(() => {
    return roundUpBy1000(marginRecommended) * currentSets;
  }, [marginRecommended, currentSets]);

  const requiredMargin = useMemo(() => {
    return marginRequired * currentSets;
  }, [marginRequired, currentSets]);

  const maxValue = useBuilderMaxStrategySetsValue();

  const validateInput = useCallback(
    (value) => {
      if (STRATEGY_SETS_MIN_VALUE <= value && value <= maxValue) {
        changeErrorMessages((prevVal) => prevVal.filter((i) => i.inputName !== STRATEGY_SETS_INPUT_NAME));
      } else {
        changeErrorMessages((prevVal) => [
          ...prevVal.filter((i) => i.inputName !== STRATEGY_SETS_INPUT_NAME),
          { inputName: STRATEGY_SETS_INPUT_NAME, errorMessage: `セット数は1以上${maxValue}までご設定ください。` },
        ]);
      }
    },
    [maxValue],
  );

  const validateName = useCallback((value) => {
    if (String(value).length > 40) {
      changeErrorMessages((prevVal) => [
        ...prevVal.filter((i) => i.inputName !== STRATEGY_NAME_INPUT_NAME),
        { inputName: STRATEGY_NAME_INPUT_NAME, errorMessage: NAME_VALIDATION_ERROR },
      ]);
    } else {
      changeErrorMessages((prevVal) => prevVal.filter((i) => i.inputName !== STRATEGY_NAME_INPUT_NAME));
    }
  }, []);

  const isDisabledConfirmButton = Boolean(errorMessages.length);

  const logicLabel = isTechBuilder ? 'テクニカルビルダー名' : '自動売買グループ';

  useEffect(() => {
    const { orderName: orderNameValue } = orderNameRef.current;
    if (isOpen) {
      setOrderName(orderNameValue);
      setSets(DEFAULT_ORDER_SETS_VALUE);
    }
  }, [isOpen]);

  return {
    modalTitle: 'カート追加 確認',
    errorMessages,
    name: {
      label: logicLabel,
      get: currentOrderName,
      set: changeOrderName,
      name: STRATEGY_NAME_INPUT_NAME,
      validateFunction: validateName,
    },
    sets: {
      label: 'セット数',
      get: currentSets,
      set: setSets,
      name: STRATEGY_SETS_INPUT_NAME,
      validateFunction: validateInput,
    },
    margin: {
      label: RECOMMENDED_MARGIN_LABEL,
      get: formatNumberToDisplayedString({ value: roundedMarginRecommended, withoutPlus: true }),
      tooltip: MARGIN_TOOLTIP,
    },
    marginRequired: {
      label: REQUIRED_MARGIN_LABEL,
      get: formatNumberToDisplayedString({ value: requiredMargin, withoutPlus: true }),
      tooltip: REQUIRED_MARGIN_TOOLTIP,
    },
    submit: {
      label: 'カートに追加',
      handler: addToCart,
      isLoading: addToCartIsLoading,
      isDisabled: isDisabledConfirmButton,
    },
    techSubmit: {
      label: 'カートに追加',
      handler: addTechToCart,
      isLoading: addToCartIsLoading,
      isDisabled: isDisabledConfirmButton,
    },
  };
};

export const useBuilderRunNowLogic = ({
  isModalOpen: isOpen,
  closeModal,
  handleSuccess,
  handleFail,
  isTechBuilder = false,
}) => {
  const [errorMessages, changeErrorMessages] = useState([]);

  const dispatch = useDispatch();

  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const termId = useSelector((state) => state.builder.selectedTermId);
  const activeCurrency = useSelector((state) => state.builder.activeCurrency);

  const rates = useSelector((state) => state.currencies.rates[activeCurrency]);
  const marginRequired = useBuilderMarginRequirement(rates, isTechBuilder);

  const simulationStatsKeyName = isTechBuilder ? 'simulationStat' : 'simulationStats';
  const marginRecommended = useSelector(
    (state) =>
      state.builder.simulationData[termId ?? defaultSelectionTermId]?.[simulationStatsKeyName]?.marginRecommended ?? 0,
  );

  const addToPortfolioIsLoading = useSelector((state) => state.builder.addToPortfolioIsLoading);

  const orderName = useBuilderLogicName(isTechBuilder);
  const getBuilderOrderRequestData = useGetBuilderOrderRequestData();
  const getTechBuilderOrderRequestData = useGetTechBuilderOrderRequestData();

  const orderNameRef = useRef({});
  useEffect(() => {
    if (orderNameRef?.current) orderNameRef.current = { orderName };
  }, [orderName]);

  useEffect(() => {
    if (isOpen) changeErrorMessages([]);
  }, [isOpen]);

  const [currentOrderName, setOrderName] = useState(orderName);
  const [currentSets, setSets] = useState(DEFAULT_ORDER_SETS_VALUE);

  const serviceId = useGetBuilderServiceId();

  const successCallback = useCallback(() => {
    closeModal();
    dispatch(getApGroupRequest({ serviceId }));

    if (handleSuccess) handleSuccess(currentOrderName !== '' ? currentOrderName : orderName);
  }, [closeModal, dispatch, serviceId, handleSuccess, currentOrderName, orderName]);

  const techSuccessCallback = useCallback(() => {
    closeModal();
    dispatch(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[AP_GROUP_STATUSES.ACTIVE] }));

    if (handleSuccess) handleSuccess(currentOrderName !== '' ? currentOrderName : orderName);
  }, [closeModal, dispatch, handleSuccess, currentOrderName, orderName]);

  const failCallback = useCallback(() => {
    closeModal();
    if (handleFail) handleFail(currentOrderName !== '' ? currentOrderName : orderName);
  }, [closeModal, handleFail, currentOrderName, orderName]);
  const addToPortfolio = useCallback(() => {
    const orderRequestData = getBuilderOrderRequestData(currentOrderName);
    dispatch(
      addToPortfolioBuilderRequest({ strategySets: currentSets, orderRequestData, successCallback, failCallback }),
    );
  }, [dispatch, currentOrderName, getBuilderOrderRequestData, currentSets, successCallback, failCallback]);

  const changeOrderName = useCallback((value) => {
    let newOrderName = String(value);
    if (newOrderName.length > ORDER_NAME_MAX_LENGTH) newOrderName = newOrderName.slice(0, ORDER_NAME_MAX_LENGTH);

    setOrderName(newOrderName);
  }, []);

  const recommendedMargin = useMemo(() => {
    return roundUpBy1000(marginRecommended) * currentSets;
  }, [marginRecommended, currentSets]);

  const requiredMargin = useMemo(() => {
    return marginRequired * currentSets;
  }, [marginRequired, currentSets]);

  const summaryInfo = useSummaryInfoByServiceId(serviceId);

  const addToTechPortfolio = useCallback(() => {
    const orderRequestData = getTechBuilderOrderRequestData(currentOrderName);
    const successValidationCallback = () => {
      if (requiredMargin > summaryInfo.orderableMargin) {
        dispatch(
          openErrorInfoModal({
            title: 'エラー',
            message: BUILDER_REQUIRED_MARGIN_VALIDATION_MESSAGE,
            buttonText: '閉じる',
          }),
        );
      } else {
        dispatch(
          addToPortfolioTechBuilderRequest({
            strategySets: currentSets,
            orderRequestData,
            successCallback: techSuccessCallback,
            failCallback,
          }),
        );
      }
    };
    successValidationCallback();
  }, [
    getTechBuilderOrderRequestData,
    currentOrderName,
    requiredMargin,
    summaryInfo.orderableMargin,
    dispatch,
    currentSets,
    techSuccessCallback,
    failCallback,
  ]);

  const maxValue = useBuilderMaxStrategySetsValue();

  const validateInput = useCallback(
    (value) => {
      if (STRATEGY_SETS_MIN_VALUE <= value && value <= maxValue) {
        changeErrorMessages((prevVal) => prevVal.filter((i) => i.inputName !== STRATEGY_SETS_INPUT_NAME));
      } else {
        changeErrorMessages((prevVal) => [
          ...prevVal.filter((i) => i.inputName !== STRATEGY_SETS_INPUT_NAME),
          { inputName: STRATEGY_SETS_INPUT_NAME, errorMessage: `セット数は1以上${maxValue}までご設定ください。` },
        ]);
      }
    },
    [maxValue],
  );

  const validateName = useCallback((value) => {
    if (String(value).length > 40) {
      changeErrorMessages((prevVal) => [
        ...prevVal.filter((i) => i.inputName !== STRATEGY_NAME_INPUT_NAME),
        { inputName: STRATEGY_NAME_INPUT_NAME, errorMessage: NAME_VALIDATION_ERROR },
      ]);
    } else {
      changeErrorMessages((prevVal) => prevVal.filter((i) => i.inputName !== STRATEGY_NAME_INPUT_NAME));
    }
  }, []);

  const isDisabledConfirmButton = Boolean(errorMessages.length);

  const logicLabel = isTechBuilder ? 'テクニカルビルダー名' : '自動売買グループ';

  useEffect(() => {
    const { orderName: orderNameValue } = orderNameRef.current;
    if (isOpen) setOrderName(orderNameValue);
  }, [isOpen]);

  return {
    modalTitle: '今すぐ稼働 確認',
    errorMessages,
    name: {
      label: logicLabel,
      get: currentOrderName,
      set: changeOrderName,
      name: STRATEGY_NAME_INPUT_NAME,
      validateFunction: validateName,
    },
    sets: {
      label: 'セット数',
      get: currentSets,
      set: setSets,
      name: STRATEGY_SETS_INPUT_NAME,
      validateFunction: validateInput,
    },
    margin: {
      label: RECOMMENDED_MARGIN_LABEL,
      get: recommendedMargin,
      tooltip: MARGIN_TOOLTIP,
    },
    marginRequired: {
      label: REQUIRED_MARGIN_LABEL,
      get: requiredMargin,
      tooltip: REQUIRED_MARGIN_TOOLTIP,
    },
    submit: {
      label: '稼働開始',
      handler: addToPortfolio,
      isLoading: addToPortfolioIsLoading,
      isDisabled: isDisabledConfirmButton,
    },
    techSubmit: {
      label: '稼働開始',
      handler: addToTechPortfolio,
      isLoading: addToPortfolioIsLoading,
      isDisabled: isDisabledConfirmButton,
    },
    message:
      'セット数を変更すると、取引数量も変更されます。稼働開始後、必ずホーム画面のポートフォリオより取引数量をご確認ください。',
  };
};

export const useBuilderRunNowResultsLogic = ({ orderName, closeModal }) => {
  const dispatch = useDispatch();

  const orderType = useSelector((state) => state.builder.orderType);
  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);

  const modifiedOrderName = useMemo(
    () =>
      `${orderName}${orderType === BUILDER_ORDER_TYPES.TECH.ID ? '' : `_${BUILDER_ORDER_TYPES_NAME_BY_ID[orderType]}`}`,
    [orderType, orderName],
  );

  const orderInfo = useMemo(
    () =>
      `・${modifiedOrderName}\n   ${orderSettingsList.length}件中${orderSettingsList.length}件自動売買が稼働しました`,
    [modifiedOrderName, orderSettingsList],
  );

  const closeSuccessModal = useCallback(() => {
    closeModal();
    dispatch(resetBuilderOrdersTables());
    dispatch(getBuilderChartDataRequest());
  }, [closeModal, dispatch]);

  return {
    success: {
      modalTitle: '自動売買が稼働しました。',
      title: '注文の状況はホーム画面のポートフォリオ内にて確認できます。',
      message: orderInfo,
    },
    fail: {
      modalTitle: '稼働できなかった自動売買があります。',
      title1: '稼働した注文についてはホーム画面のポートフォリオ内にて確認できます。',
      title2: '失敗した注文については証拠金状況や注文状況等を今一度ご確認ください。',
      failLabel: '[失敗]',
      orderName: modifiedOrderName,
      message: '稼働ができませんでした',
    },
    buttons: {
      submit: {
        label: 'ホームに戻る',
      },
      cancel: {
        label: '閉じる',
        handler: closeSuccessModal,
      },
    },
  };
};

export const useTechRunNowResultsLogic = ({ orderName, closeModal }) => {
  const dispatch = useDispatch();

  const closeSuccessModal = useCallback(() => {
    closeModal();
    dispatch(resetBuilderOrdersTables());
    dispatch(resetBuilderSimulationData());
    dispatch(getBuilderChartDataRequest());
  }, [closeModal, dispatch]);

  return {
    success: {
      modalTitle: 'テクニカルロジックが稼働しました。',
      title: 'テクニカルロジックの設定条件はホーム画面のポートフォリオ内にて確認できます。',
      message: `・${orderName}`,
    },
    fail: {
      modalTitle: 'テクニカルロジックの稼働に失敗しました。',
      title1: '証拠金状況や注文状況等を今一度ご確認ください。',
      failLabel: '[失敗]',
      orderName,
      message: '稼働ができませんでした',
    },
    buttons: {
      submit: {
        label: 'ホームに戻る',
      },
      cancel: {
        label: '閉じる',
        handler: closeSuccessModal,
      },
    },
  };
};

const hasNegativeEntryPrice = (orderSetting) =>
  Number(orderSetting.entryPrice1) < 0 || Number(orderSetting.entryPrice2) < 0;

const useHasNegativeEntryPriceRelative = () => {
  const { basePriceAsk, basePriceBid } = useBuilderMultiBasePriceForSide();

  return useCallback(
    (orderSetting) => {
      const basePrice = orderSetting.buySell === BUY_SELL_MAIN.BUY.ID ? basePriceAsk : basePriceBid;
      return Number(basePrice) + Number(orderSetting.entryPrice1Relative) < 0;
    },
    [basePriceAsk, basePriceBid],
  );
};

export const useCheckOnNegativePriceWhenSimulate = () => {
  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);
  const orderType = useSelector((state) => state.builder.orderType);
  const profitLossChartIsVisible = useSelector((state) => state?.other?.profitLossChartIsVisible);
  const dispatch = useDispatch();
  const isWebApp = checkIsWebApp();

  const hasNegativeEntryPriceRelative = useHasNegativeEntryPriceRelative();

  const doValidateTechSimulation = useCallback(
    (successCallback, errorCallback) => {
      if (orderSettingsList.some(hasNegativeEntryPriceRelative)) {
        dispatch(hideProfitlossChart());
        dispatch(
          openErrorInfoModal({
            title: 'エラー',
            message: `エントリー価格がマイナスの注文が存在するためシミュレーションができませんでした。再度設定値をご確認ください。`,
            buttonText: '閉じる',
            buttonCallback: errorCallback,
          }),
        );
        return;
      }

      if (!profitLossChartIsVisible && !isWebApp) dispatch(showProfitlossChart());

      successCallback();
    },
    [dispatch, profitLossChartIsVisible, isWebApp, hasNegativeEntryPriceRelative, orderSettingsList],
  );

  const doValidateSimulation = useCallback(
    (successCallback, errorCallback) => {
      if (orderSettingsList.some(hasNegativeEntryPrice)) {
        dispatch(hideProfitlossChart());
        dispatch(
          openErrorInfoModal({
            title: 'エラー',
            message: `エントリー価格がマイナスの注文が存在するためシミュレーションができませんでした。再度設定値をご確認ください。`,
            buttonText: '閉じる',
            buttonCallback: errorCallback,
          }),
        );
        return;
      }

      if (!profitLossChartIsVisible && !isWebApp) dispatch(showProfitlossChart());

      successCallback();
    },
    [dispatch, orderSettingsList, profitLossChartIsVisible, isWebApp],
  );

  if (orderType === BUILDER_ORDER_TYPES.TECH.ID) {
    return doValidateTechSimulation;
  }
  return doValidateSimulation;
};
