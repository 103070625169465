import { all, put, takeEvery, call, takeLatest, select } from 'redux-saga/effects';
import { CFD, ETF, FX, MIXED } from '../../constants';
import {
  GET_SELECTION_TAGS_REQUEST,
  GET_SELECTIONS_BY_PARAMS_REQUEST,
  ADD_TO_CART_REQUEST,
  GET_SELECTION_MODAL_INFO_REQUEST,
  CREATE_SELECTION_AP_GROUP_REQUEST,
  GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_REQUEST,
} from '../actionConstants/autoSelectConstants';
import {
  getSelectionTagsRequestStartLoading,
  getSelectionTagsRequestEndLoading,
  getSelectionTagsSuccess,
  getSelectionsByParamsRequestStartLoading,
  getSelectionsByParamsRequestEndLoading,
  getSelectionsByParamsSuccess,
  getSelectionForValidatingAddToCartStartLoading,
  getSelectionForValidatingAddToCartStopLoading,
  getSelectionForValidatingAddToCartSuccess,
  addToCartRequestStartLoading,
  addToCartRequestEndLoading,
  getSelectionModalInfoRequestStartLoading,
  getSelectionModalInfoRequestStopLoading,
  getSelectionModalInfoSuccess,
  createSelectionApGroupRequestStartLoading,
  createSelectionApGroupRequestEndLoading,
  getSelectionModalTermInfoRequestStartLoading,
  getSelectionModalTermInfoRequestEndLoading,
} from '../actions/autoSelectActions';
import { getSelectionTags, getSelectionsByParams, addToCart, createApGroup } from '../../api/autoSelectApi';
import { addLabToCart } from '../../api/labApi';
import { getSelectionDetailData } from '../../api/strategyApi';
import { errorHandler } from './errorSaga';
import { sendNotificationSuccess } from '../actions/notificationActions';
import { TAG_ID_ALL } from '../../constants/select';
import { calcComprehensiveEvaluationByAttibute, includeComprehensiveEvaluation, isArray } from '../../utils';

const transformTags = (tags, attrName) => {
  return tags
    .sort((a, b) => {
      if (a.sortNo === b.sortNo) {
        return 0;
      }
      return a.sortNo > b.sortNo ? 1 : -1;
    })
    .reduce((acc, curr) => {
      const { parentTagId } = curr;
      if (parentTagId == null) {
        acc.push({ ...curr, [attrName]: [] });
      } else {
        const found = acc.find(({ tagId }) => tagId === Number(parentTagId));
        if (found) {
          found[attrName].push({
            ...curr,
            // おすすめセレクトIDを array に変換
            recommendationId: curr.recommendationId == null ? [] : curr.recommendationId,
          });
        }
      }
      return acc;
    }, []);
};

const normalizeAllTag = (tags, attr, parentTagId) => {
  // name でグルーピングして、sortNo は数値が小さい方を優先
  const result = Object.values(
    tags
      .flatMap((tag) => tag[attr])
      .filter((tag) => tag)
      .reduce((acc, curr) => {
        const { tagId, sortNo, badge, name, ...rest } = curr;
        const row = acc[name] ?? {};
        const parentTagIds = row.parentTagIds ?? [];
        parentTagIds.push(Number(curr.parentTagId));
        const tagIds = row.tagId ?? [];
        tagIds.push(tagId);
        // 名前が同じおすすめセレクトID(配列)をマージする
        const recommendationId = row.recommendationId ?? [];
        recommendationId.push(...curr.recommendationId);
        const existSortNo = row.sortNo ?? Number.MAX_VALUE;
        acc[name] = {
          ...row,
          ...rest,
          name,
          tagId: tagIds,
          sortNo: sortNo < existSortNo ? sortNo : existSortNo,
          parentTagId,
          parentTagIds,
          badge: row.badge || badge,
          defaultFlg: false,
          recommendationId,
        };
        return acc;
      }, {}),
  );
  result.sort((a, b) => a.sortNo - b.sortNo);
  return result;
};

function* getSelectionTagsRequestHandler() {
  try {
    yield put(getSelectionTagsRequestStartLoading());
    const { data: selectTags } = yield call(getSelectionTags);
    const selectionTagsData = transformTags(selectTags, 'children');
    const allTag = selectionTagsData.find((tag) => tag.tagId === TAG_ID_ALL);
    if (allTag) {
      const parentTagId = allTag.tagId;
      allTag.children = normalizeAllTag(selectionTagsData, 'children', parentTagId);
    }
    yield put(getSelectionTagsSuccess({ selectionTagsData }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getSelectionTagsRequestEndLoading());
  }
}

const transformSelectionData = (data, instrumentList) => {
  return data.map((row) => {
    let serviceIds;
    const { serviceId, simulationStats } = row;
    if (serviceId === MIXED) {
      serviceIds = Array.from(
        new Set(
          simulationStats.selectionStrategyDetailList.map((detail) => instrumentList[detail.instrumentId].serviceId),
        ),
      );
    } else {
      serviceIds = [serviceId];
    }
    return { ...row, serviceIds };
  });
};

function* getSelectionsByParamsRequestHandler(action) {
  try {
    yield put(getSelectionsByParamsRequestStartLoading());
    const {
      payload: { tagId },
    } = action;
    if (tagId == null) {
      yield put(getSelectionsByParamsSuccess({ selectionsData: [] }));
      return;
    }
    const tagIds = (isArray(tagId) ? tagId : [tagId]).filter((id) => id != null && id !== '');
    const res = yield all(tagIds.map((mapTagId) => call(getSelectionsByParams, { tagId: mapTagId })));
    const instrumentList = yield select((state) => state.settings.instrumentList);
    const selectionsData = res
      .reduce((acc, curr) => acc.concat(transformSelectionData(curr.data, instrumentList)), [])
      .map(includeComprehensiveEvaluation); // 総合評価

    yield put(getSelectionsByParamsSuccess({ selectionsData }));
  } catch (e) {
    yield call(errorHandler, { error: e });
    yield put(getSelectionsByParamsSuccess({ selectionsData: [] }));
  } finally {
    yield put(getSelectionsByParamsRequestEndLoading());
  }
}

function* getSelectionForValidatingAddToCartHandler(action) {
  try {
    yield put(getSelectionForValidatingAddToCartStartLoading());

    const {
      payload: { selectionId, selectionVersion },
    } = action;

    const termId = yield select((state) => state.constants.defaultSelectionTermId);

    const {
      data: { strategyList },
    } = yield call(getSelectionDetailData, { selectionId, selectionVersion, chartsFlg: false, termId });

    yield put(getSelectionForValidatingAddToCartSuccess({ strategyList }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getSelectionForValidatingAddToCartStopLoading());
  }
}

function* addToCartRequestHandler(action) {
  try {
    if (action.payload.selectionId) {
      const {
        payload: { selectionId, selectionVersion, selectionSets, callback },
      } = action;
      const requestBody = { selectionId, selectionVersion, selectionSets };

      yield put(addToCartRequestStartLoading({ id: selectionId }));

      yield call(addToCart, { requestBody });
      if (callback) callback();
    } else {
      const { labId, labSets, callback } = action.payload;
      const requestBody = { labId, labSets };

      yield put(addToCartRequestStartLoading({ id: labId }));

      yield call(addLabToCart, { requestBody });

      if (callback) callback();
    }

    yield put(sendNotificationSuccess({ message: 'カートに追加されました。' }));
  } catch (e) {
    yield call(errorHandler, { error: e, buttonText: 'OK' });
  } finally {
    yield put(addToCartRequestEndLoading());
  }
}

function* getSelectionModalInfoRequestHandler(action) {
  const {
    payload: { selectionId, selectionVersion, termId, termIdLoader },
  } = action;

  try {
    if (!termIdLoader) {
      yield put(getSelectionModalInfoRequestStartLoading());
    } else {
      yield put(getSelectionModalTermInfoRequestStartLoading());
    }

    const { data } = yield call(getSelectionDetailData, {
      selectionId,
      selectionVersion,
      tradesFlg: true,
      chartsFlg: true,
      termId,
      termIdLoader,
    });

    data.serviceIds = Array.from(new Set(data.strategyList.map((strategy) => strategy.strategyDetail.serviceId)));

    data.simulationStats.marginRecommendedFX = data.strategyList
      .filter((x) => x.strategyDetail.serviceId === FX)
      .reduce((accumulator, strategy) => accumulator + strategy.strategyDetail.simulationStats.marginRecommended, 0);

    data.simulationStats.marginRecommendedETF = data.strategyList
      .filter((x) => x.strategyDetail.serviceId === ETF)
      .reduce((accumulator, strategy) => accumulator + strategy.strategyDetail.simulationStats.marginRecommended, 0);

    data.simulationStats.marginRecommendedCFD = data.strategyList
      .filter((x) => x.strategyDetail.serviceId === CFD)
      .reduce((accumulator, strategy) => accumulator + strategy.strategyDetail.simulationStats.marginRecommended, 0);

    data.simulationStats.marginRequiredFX = data.strategyList
      .filter((x) => x.strategyDetail.serviceId === FX)
      .reduce((accumulator, strategy) => accumulator + strategy.strategyDetail.simulationStats.marginRequired, 0);

    data.simulationStats.marginRequiredETF = data.strategyList
      .filter((x) => x.strategyDetail.serviceId === ETF)
      .reduce((accumulator, strategy) => accumulator + strategy.strategyDetail.simulationStats.marginRequired, 0);

    data.simulationStats.marginRequiredCFD = data.strategyList
      .filter((x) => x.strategyDetail.serviceId === CFD)
      .reduce((accumulator, strategy) => accumulator + strategy.strategyDetail.simulationStats.marginRequired, 0);

    // 総合評価
    if (data.attribute) {
      data.attribute.comprehensiveEvaluation = calcComprehensiveEvaluationByAttibute(data.attribute);
    }

    yield put(getSelectionModalInfoSuccess({ selectionModalInfo: data, termId }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    if (!termIdLoader) {
      yield put(getSelectionModalInfoRequestStopLoading());
    } else {
      yield put(getSelectionModalTermInfoRequestEndLoading());
    }
  }
}

function* createSelectionApGroupRequestHandler(action) {
  try {
    const {
      payload: { callback },
    } = action;
    let requestBody;

    const serviceId = yield select((state) => state.auth.serviceId);

    yield put(createSelectionApGroupRequestStartLoading());

    if (action.payload.selectionId) {
      const {
        payload: { selectionId, selectionVersion, sets },
      } = action;

      requestBody = { selectionId, selectionVersion, sets };
    } else {
      const {
        payload: { labId, sets },
      } = action;

      requestBody = { labId, sets };
    }

    const { data } = yield call(createApGroup, { serviceId, requestBody });

    if (callback) callback(data);
  } catch (e) {
    yield call(errorHandler, { error: e, buttonText: 'OK' });
  } finally {
    yield put(createSelectionApGroupRequestEndLoading());
  }
}

export default function* autoSelectSagaHandler() {
  yield takeEvery(GET_SELECTION_TAGS_REQUEST, getSelectionTagsRequestHandler);
  yield takeLatest(GET_SELECTIONS_BY_PARAMS_REQUEST, getSelectionsByParamsRequestHandler);
  yield takeLatest(GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_REQUEST, getSelectionForValidatingAddToCartHandler);
  yield takeEvery(ADD_TO_CART_REQUEST, addToCartRequestHandler);
  yield takeLatest(GET_SELECTION_MODAL_INFO_REQUEST, getSelectionModalInfoRequestHandler);
  yield takeEvery(CREATE_SELECTION_AP_GROUP_REQUEST, createSelectionApGroupRequestHandler);
}
